
import { Hero } from "@/components/Hero";
import { Benefits } from "@/components/Benefits";
import { Services } from "@/components/Services";
import { Stats } from "@/components/Stats";
import { Reviews } from "@/components/Reviews";
import { FAQ } from "@/components/FAQ";
import { AppPreview } from "@/components/AppPreview";
import { BlogRoll } from "@/components/BlogRoll";
import { FormProvider } from "@/contexts/FormContext";
import { LiveSalesPopup } from "@/components/LiveSalesPopup";
import { SavingsCalculator } from "@/components/SavingsCalculator";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const VälittäjäVertailu = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  // Ensure we're on the correct language path
  useEffect(() => {
    const currentPath = window.location.pathname;
    const langCode = i18n.language;
    const expectedPath = `/${langCode}/${langCode === 'fi' ? 'fin' : langCode === 'sv' ? 'swe' : langCode === 'en' ? 'eng' : langCode === 'et' ? 'est' : 'ger'}`;
    
    if (!currentPath.startsWith(expectedPath)) {
      navigate(expectedPath);
    }
  }, [i18n.language, navigate]);

  return (
    <FormProvider>
      <div className="overflow-hidden">
        <Hero />
        <Services />
        <Stats />
        <Benefits />
        <SavingsCalculator />
        <AppPreview />
        <BlogRoll />
        <Reviews />
        <FAQ />
        <LiveSalesPopup />
      </div>
    </FormProvider>
  );
};

export default VälittäjäVertailu;
