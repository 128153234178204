
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useToast } from "@/hooks/use-toast";
import { apiService } from "@/services/apiService";
import { useForm } from "@/contexts/FormContext";

export const LoginForm = ({ 
  email, 
  onCancel,
  onLoginSuccess
}: { 
  email: string; 
  onCancel: () => void;
  onLoginSuccess: (token: string) => Promise<void>;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const [password, setPassword] = useState("");
  const { toast } = useToast();
  const { updateFormData } = useForm();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await apiService.login({ email, password });
      const responseData = await response.json();
      
      if (responseData.statusCode === 200 && responseData.data?.accessToken) {
        const token = responseData.data.accessToken;
        updateFormData({ authorization: token });
        await onLoginSuccess(token);
      } else {
        throw new Error(responseData.message || "Login failed");
      }
    } catch (error) {
      toast({
        title: "Virhe kirjautumisessa",
        description: "Tarkista salasanasi ja yritä uudelleen",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleForgotPassword = async () => {
    setIsResetting(true);
    try {
      const response = await apiService.forgotPassword(email);
      
      if (response.ok) {
        toast({
          title: "Linkki lähetetty",
          description: "Salasanan nollauslinkki on lähetetty sähköpostiisi. Voit jatkaa kirjautumista kun olet vaihtanut salasanasi.",
        });
      }
    } catch (error) {
      toast({
        title: "Virhe",
        description: "Virhe lähettäessä salasanan nollauslinkkiä. Tarkista verkkoyhteys.",
        variant: "destructive",
      });
    } finally {
      setIsResetting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="space-y-2 text-center">
        <h2 className="text-2xl font-bold text-center text-[#1B365D]">
          Kirjaudu sisään
        </h2>
        <p className="text-gray-600 text-sm">
          Tiedoillasi löytyy jo tili palvelustamme. Ole hyvä ja kirjaudu sisään jatkaaksesi
        </p>
      </div>
      
      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="email">Sähköposti</Label>
          <Input
            id="email"
            type="email"
            value={email}
            disabled
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="password">Salasana</Label>
          <Input
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Syötä salasanasi"
            required
          />
          <button
            type="button"
            onClick={handleForgotPassword}
            disabled={isResetting}
            className="text-sm text-[#1B365D] hover:underline mt-1 disabled:opacity-50"
          >
            {isResetting ? "Lähetetään..." : "Unohditko salasanasi?"}
          </button>
        </div>
      </div>

      <div className="space-y-4">
        <Button 
          type="submit" 
          className="w-full bg-[#1B365D] hover:bg-[#152C4A]"
          disabled={isLoading}
        >
          {isLoading ? "Kirjaudutaan..." : "Kirjaudu sisään"}
        </Button>

        <Button 
          type="button" 
          variant="outline"
          className="w-full"
          onClick={onCancel}
        >
          Käytä toista sähköpostia
        </Button>
      </div>
    </form>
  );
};
