
import { useState, useEffect, useRef } from "react";
import { useToast } from "@/hooks/use-toast";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Calculator } from "lucide-react";
import type { PlaceResult } from "@/hooks/useGoogleMaps";
import { calculateEstimate } from "@/utils/zipCodePrices";
import { PropertyDetailsForm } from "@/components/hinta-arvio/PropertyDetailsForm";
import { ContactForm } from "@/components/hinta-arvio/ContactForm";
import { PriceResult } from "@/components/hinta-arvio/PriceResult";
import { HowItWorks } from "@/components/hinta-arvio/HowItWorks";
import { FormProvider, useForm } from "@/contexts/FormContext";
import { useTranslation } from "react-i18next";

interface PropertyDetails {
  address?: PlaceResult;
  size: string;
  condition: 'good' | 'average' | 'poor';
  propertyType: 'apartment' | 'house' | 'rowhouse';
  buildYear?: string;
  email?: string;
  phone?: string;
}

const conditionFactors = {
  good: 1.1,
  average: 1.0,
  poor: 0.9
};

const propertyTypeFactors = {
  apartment: 1.0,
  house: 1.15,
  rowhouse: 1.05
};

const calculatePriceIncrease = () => {
  const baseYear = 2019;
  const currentYear = new Date().getFullYear();
  const yearsSince2019 = currentYear - baseYear;
  return Math.pow(1.02, yearsSince2019);
};

function PriceEstimationContent() {
  const [propertyDetails, setPropertyDetails] = useState<PropertyDetails>({
    size: '',
    condition: 'average',
    propertyType: 'apartment',
    buildYear: '',
    email: '',
    phone: ''
  });
  const [step, setStep] = useState(1);
  const [estimatedPrice, setEstimatedPrice] = useState<number | null>(null);
  const [isCalculating, setIsCalculating] = useState(false);
  const { toast } = useToast();
  const { updateFormData } = useForm();
  const hasUpdated = useRef(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (hasUpdated.current) return;

    const relevantData = {
      address: propertyDetails.address,
      size: propertyDetails.size,
      condition: propertyDetails.condition,
      propertyType: propertyDetails.propertyType,
      buildYear: propertyDetails.buildYear,
      email: propertyDetails.email,
      phone: propertyDetails.phone
    };
    
    if (relevantData.address || relevantData.size || relevantData.email || relevantData.phone) {
      console.log('Updating form data with:', relevantData);
      updateFormData(relevantData);
      hasUpdated.current = true;
    }
  }, [
    propertyDetails.address,
    propertyDetails.size,
    propertyDetails.condition,
    propertyDetails.propertyType,
    propertyDetails.buildYear,
    propertyDetails.email,
    propertyDetails.phone,
    updateFormData
  ]);

  const handlePropertyDetailsChange = (details: Partial<PropertyDetails>) => {
    setPropertyDetails(prev => ({...prev, ...details}));
    hasUpdated.current = false;
  };

  const handleNextStep = () => {
    if (!propertyDetails.address?.components.postal_code || !propertyDetails.size) {
      toast({
        title: "Puuttuvia tietoja",
        description: "Täytä kaikki pakolliset kentät",
        variant: "destructive",
      });
      return;
    }
    setStep(2);
  };

  const handleCalculate = async () => {
    if (!propertyDetails.email || !propertyDetails.phone) {
      toast({
        title: t('error.title', 'Puuttuvia tietoja'),
        description: t('error.missingFields', 'Täytä sähköposti ja puhelinnumero'),
        variant: "destructive",
      });
      return;
    }

    setIsCalculating(true);
    
    try {
      const zipCode = propertyDetails.address?.components.postal_code.replace(/\s+/g, '');
      
      if (!zipCode) {
        throw new Error("No zipcode found");
      }

      const baseEstimate = await calculateEstimate(
        zipCode,
        parseFloat(propertyDetails.size)
      );

      if (!baseEstimate) {
        toast({
          title: t('error.title', 'Virhe'),
          description: t('error.estimateCalculation', `Hinta-arviota ei voitu laskea alueelle ${zipCode}`),
          variant: "destructive",
        });
        return;
      }

      const adjustedEstimate = Math.round(
        baseEstimate * 
        conditionFactors[propertyDetails.condition] * 
        propertyTypeFactors[propertyDetails.propertyType] * 
        calculatePriceIncrease()
      );

      updateFormData({
        ...propertyDetails,
        email: propertyDetails.email,
        phone: propertyDetails.phone
      });

      setEstimatedPrice(adjustedEstimate);
      setStep(3);
      
      toast({
        title: t('success.title', 'Hinta-arvio laskettu'),
        description: t('success.description', 'Arviomme perustuu alueen keskimääräisiin neliöhintoihin'),
      });
    } catch (error) {
      console.error("Error calculating estimate:", error);
      toast({
        title: t('error.title', 'Virhe'),
        description: t('error.generic', 'Hinta-arvion laskemisessa tapahtui virhe'),
        variant: "destructive",
      });
    } finally {
      setIsCalculating(false);
    }
  };

  return (
    <Card className="shadow-lg backdrop-blur-sm bg-white/95">
      <CardHeader>
        <CardTitle className="flex items-center gap-2">
          <Calculator className="h-5 w-5 text-[#1B365D]" />
          {step === 1 && t('hintaArvio.form.propertyDetails.title')}
          {step === 2 && t('hintaArvio.form.contact.title')}
          {step === 3 && t('hintaArvio.result.title')}
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-6">
        {step === 1 && (
          <PropertyDetailsForm
            propertyDetails={propertyDetails}
            onPropertyDetailsChange={handlePropertyDetailsChange}
            onNext={handleNextStep}
          />
        )}

        {step === 2 && (
          <ContactForm
            email={propertyDetails.email || ''}
            phone={propertyDetails.phone || ''}
            onEmailChange={(email) => handlePropertyDetailsChange({ email })}
            onPhoneChange={(phone) => handlePropertyDetailsChange({ phone })}
            onSubmit={handleCalculate}
            isCalculating={isCalculating}
          />
        )}

        {step === 3 && estimatedPrice && (
          <PriceResult estimatedPrice={estimatedPrice} />
        )}
      </CardContent>
    </Card>
  );
}

export default function PriceEstimation() {
  const { t } = useTranslation();
  
  return (
    <main 
      className="relative min-h-screen w-full"
      style={{
        backgroundImage: `url('/lovable-uploads/6d9c45ac-6283-4e43-ba1b-c6595962a6ba.png')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <div className="absolute inset-0 bg-black/40 backdrop-blur-[2px] z-0" />

      <div className="relative z-10 w-full min-h-screen pt-24 pb-16 px-4">
        <div className="max-w-3xl mx-auto space-y-8">
          <div className="text-center space-y-4">
            <h1 className="text-4xl font-bold text-white">
              {t('hintaArvio.title')}
            </h1>
            <p className="text-lg text-white/90 max-w-2xl mx-auto">
              {t('hintaArvio.description')}
            </p>
          </div>

          <FormProvider>
            <PriceEstimationContent />
          </FormProvider>

          <HowItWorks />
        </div>
      </div>
    </main>
  );
}
