
import WhyChooseSalaovi from "@/components/WhyChooseSalaovi";
import { HeroSection } from "@/components/valittajille/HeroSection";
import { StatsSection } from "@/components/valittajille/StatsSection";
import { PricingSection } from "@/components/valittajille/PricingSection";
import { ContactSection } from "@/components/valittajille/ContactSection";
import { FinalCTA } from "@/components/valittajille/FinalCTA";

const ValittajillePage = () => {
  return (
    <div className="pt-16">
      <HeroSection />
      <StatsSection />
      <WhyChooseSalaovi />
      <PricingSection />
      <ContactSection />
      <FinalCTA />
    </div>
  );
};

export default ValittajillePage;
