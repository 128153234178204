
import { useQuery } from "@tanstack/react-query";
import { Card } from "@/components/ui/card";
import { blogService, type BlogPost } from "@/services/blogService";
import { format } from "date-fns";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Tag } from "lucide-react";

const stripHtml = (html: string) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || "";
};

export const Blog = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState<string>('all');
  const postsPerPage = 9;
  
  const { data: blogPosts, isLoading, error } = useQuery({
    queryKey: ['blogPosts'],
    queryFn: blogService.getBlogPosts,
  });

  // Get unique categories from all posts, excluding both uncategorized variations
  const categories = blogPosts?.reduce((acc: { name: string; slug: string; }[], post) => {
    post.categories?.forEach(category => {
      if (!acc.find(c => c.slug === category.slug) && 
          category.slug !== 'uncategorized' && 
          category.slug !== 'uncategorized-fi') {
        acc.push(category);
      }
    });
    return acc;
  }, []) ?? [];

  // Filter posts by category
  const filteredPosts = blogPosts?.filter(post => {
    if (selectedCategory === 'all') return true;
    return post.categories?.some(category => category.slug === selectedCategory);
  });

  const totalPages = filteredPosts ? Math.ceil(filteredPosts.length / postsPerPage) : 0;
  const paginatedPosts = filteredPosts?.slice(
    (currentPage - 1) * postsPerPage,
    currentPage * postsPerPage
  );

  const getImageUrl = (url: string | null) => {
    if (!url) return null;
    if (url.startsWith('http')) return url;
    return `https://yfhzflexahlxpaogypbx.supabase.co/storage/v1/object/public/blog_images/${url}`;
  };

  if (isLoading) {
    return (
      <div className="pt-20 min-h-screen bg-white">
        <div className="max-w-5xl mx-auto px-4">
          <h1 className="text-4xl font-bold text-[#1B365D] mb-12 text-center">Blogi</h1>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[1, 2, 3].map((index) => (
              <Card key={index} className="p-6 animate-pulse">
                <div className="h-48 bg-gray-200 rounded mb-4"></div>
                <div className="h-4 bg-gray-200 rounded w-1/4 mb-2"></div>
                <div className="h-6 bg-gray-200 rounded w-3/4 mb-3"></div>
                <div className="h-20 bg-gray-200 rounded mb-4"></div>
                <div className="h-4 bg-gray-200 rounded w-1/3"></div>
              </Card>
            ))}
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="pt-20 min-h-screen bg-white">
        <div className="max-w-5xl mx-auto px-4 text-center">
          <h1 className="text-4xl font-bold text-[#1B365D] mb-6">Blogi</h1>
          <p className="text-red-500">Virhe ladattaessa blogitekstejä. Yritä myöhemmin uudelleen.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="pt-20 min-h-screen bg-gradient-to-b from-white to-gray-50">
      <section className="py-20 px-4">
        <div className="max-w-5xl mx-auto">
          <div className="flex flex-col gap-4 mb-12">
            <h1 className="text-4xl font-bold text-[#1B365D] mb-2">Blogi</h1>
            <div className="flex flex-wrap gap-2 mt-4">
              <Button
                variant={selectedCategory === 'all' ? 'default' : 'outline'}
                onClick={() => setSelectedCategory('all')}
                className="rounded-full"
              >
                Kaikki kategoriat
              </Button>
              {categories.map((category) => (
                <Button
                  key={category.slug}
                  variant={selectedCategory === category.slug ? 'default' : 'outline'}
                  onClick={() => setSelectedCategory(category.slug)}
                  className="rounded-full"
                >
                  <Tag className="w-4 h-4 mr-1" />
                  {category.name}
                </Button>
              ))}
            </div>
          </div>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {paginatedPosts?.map((post) => (
              <div key={post.id} className="group cursor-pointer">
                <Link 
                  to={`${post.slug}`} 
                  className="block h-full"
                >
                  <Card className="h-full overflow-hidden transition-all duration-300 hover:shadow-lg">
                    {post.featured_image && (
                      <div className="aspect-video overflow-hidden">
                        <img 
                          src={getImageUrl(post.featured_image)}
                          alt={post.title}
                          className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                          loading="lazy"
                        />
                      </div>
                    )}
                    <div className="p-6">
                      <p className="text-sm text-gray-500 mb-2">
                        {post.published_at ? format(new Date(post.published_at), 'd.M.yyyy') : ''}
                      </p>
                      <h2 className="text-xl font-semibold text-[#1B365D] mb-3 line-clamp-2">{post.title}</h2>
                      <div className="flex flex-wrap gap-2 mb-3">
                        {post.categories?.filter(category => 
                          category.slug !== 'uncategorized' && 
                          category.slug !== 'uncategorized-fi'
                        ).map((category) => (
                          <span 
                            key={category.slug}
                            className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800"
                          >
                            <Tag className="w-3 h-3 mr-1" />
                            {category.name}
                          </span>
                        ))}
                      </div>
                      <p className="text-gray-700 mb-4 line-clamp-3">
                        {stripHtml(post.excerpt || '')}
                      </p>
                      <span className="text-[#1B365D] font-medium group-hover:underline inline-flex items-center">
                        Lue lisää 
                        <svg 
                          className="ml-1 w-4 h-4 transition-transform group-hover:translate-x-1" 
                          viewBox="0 0 24 24"
                        >
                          <path 
                            fill="currentColor" 
                            d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z"
                          />
                        </svg>
                      </span>
                    </div>
                  </Card>
                </Link>
              </div>
            ))}
          </div>
          
          {totalPages > 1 && (
            <div className="mt-12 flex justify-center gap-2">
              <Button
                variant="outline"
                onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
                disabled={currentPage === 1}
              >
                Edellinen
              </Button>
              {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                <Button
                  key={page}
                  variant={currentPage === page ? "default" : "outline"}
                  onClick={() => setCurrentPage(page)}
                >
                  {page}
                </Button>
              ))}
              <Button
                variant="outline"
                onClick={() => setCurrentPage(prev => Math.min(totalPages, prev + 1))}
                disabled={currentPage === totalPages}
              >
                Seuraava
              </Button>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default Blog;
