import { useEffect, useState } from 'react';
import i18next from 'i18next';

declare global {
  interface Window {
    google: any;
    initAutocomplete: () => void;
  }
}

interface AddressComponent {
  street_number?: string;
  route?: string;
  locality?: string;
  country?: string;
  postal_code?: string;
  latitude?: number;
  longitude?: number;
}

export interface PlaceResult {
  address: string;
  components: AddressComponent;
  latitude: number;
  longitude: number;
  streetViewPov?: {
    heading: number;
    pitch: number;
  };
}

const GOOGLE_MAPS_API_KEY = 'AIzaSyAkfhQ_hKSnM3dBcPPWA4RT_YCtfusjUMc';

// Map of language codes to their corresponding country codes for Places API
const languageToCountry: { [key: string]: string } = {
  fi: 'fi', // Finland
  et: 'ee', // Estonia
  sv: 'se', // Sweden
  de: 'de', // Germany
  en: 'fi'  // Default to Finland for English
};

export const useGoogleMaps = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  let timeoutId: number;

  useEffect(() => {
    if (!window.google) {
      const script = document.createElement('script');
      // Add the language parameter to the Google Maps script URL
      const currentLanguage = i18next.language;
      script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places&language=${currentLanguage}`;
      script.async = true;
      script.defer = true;
      script.onload = () => setIsLoaded(true);
      document.head.appendChild(script);
    } else {
      setIsLoaded(true);
    }
  }, []);

  const initAutocomplete = (inputElement: HTMLInputElement, callback: (result: PlaceResult | null) => void) => {
    if (!window.google) return;

    const currentLanguage = i18next.language;
    const countryCode = languageToCountry[currentLanguage] || 'fi';

    const autocomplete = new window.google.maps.places.Autocomplete(inputElement, {
      types: ['address'],
      componentRestrictions: { country: countryCode },
      language: currentLanguage
    });

    autocomplete.setFields(['address_components', 'geometry', 'formatted_address']);

    // Add a debounced listener for place changes
    autocomplete.addListener('place_changed', () => {
      // Clear any existing timeout
      if (timeoutId) {
        window.clearTimeout(timeoutId);
      }

      // Set a new timeout
      timeoutId = window.setTimeout(() => {
        const place = autocomplete.getPlace();
      
        if (!place.geometry) {
          callback(null);
          return;
        }

        const components: AddressComponent = {};
        let hasStreetNumber = false;
        
        place.address_components.forEach((component: any) => {
          const type = component.types[0];
          if (type === 'street_number') {
            components.street_number = component.short_name;
            hasStreetNumber = true;
          }
          if (type === 'route') {
            components.route = component.long_name;
          }
          if (type === 'locality') {
            components.locality = component.long_name;
          }
          if (type === 'country') {
            components.country = component.long_name;
          }
          if (type === 'postal_code') {
            components.postal_code = component.short_name;
          }
        });

        // Only proceed if we have a street number
        if (!hasStreetNumber) {
          callback(null);
          return;
        }

        const result: PlaceResult = {
          address: place.formatted_address,
          components,
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng(),
        };

        callback(result);
      }, 300); // 300ms debounce
    });
  };

  return { isLoaded, initAutocomplete };
};
