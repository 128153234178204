
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "@/components/ui/card";
import { Slider } from "@/components/ui/slider";
import { Button } from "@/components/ui/button";
import { PieChart, Pie, Cell } from "recharts";

export const SavingsCalculator = () => {
  const { t } = useTranslation();
  const [propertyValue, setPropertyValue] = useState(250000);
  const [commission, setCommission] = useState(2.5);
  const [savings, setSavings] = useState<number>(0);

  const calculateSavings = () => {
    const traditionalCommission = propertyValue * 0.04; // 4% traditional commission
    const ourCommission = propertyValue * (commission / 100);
    const potentialSavings = traditionalCommission - ourCommission;
    setSavings(Math.round(potentialSavings));
  };

  useEffect(() => {
    calculateSavings();
  }, [propertyValue, commission]);

  const handleStartComparison = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const ourCommissionAmount = propertyValue * (commission / 100);
  const pieData = [
    { name: t('calculator.savings.commission'), value: ourCommissionAmount },
    { name: t('calculator.savings.saving'), value: savings },
  ];

  const COLORS = ["#1B365D", "#86efac"];

  return (
    <section className="py-20 px-4 bg-white">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12">
          <h2 className="text-3xl md:text-4xl font-bold mb-4 text-[#1B365D]">
            {t('calculator.title')}
          </h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            {t('calculator.description')}
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl mx-auto">
          <div className="space-y-8">
            <div className="space-y-4">
              <label className="text-sm font-medium text-gray-700">
                {t('calculator.propertyValue.label')}
              </label>
              <Slider
                defaultValue={[propertyValue]}
                max={1000000}
                min={50000}
                step={1000}
                onValueChange={(value) => setPropertyValue(value[0])}
                className="w-full"
              />
              <div className="text-right text-lg font-semibold">
                {new Intl.NumberFormat('fi-FI', { style: 'currency', currency: 'EUR' }).format(propertyValue)}
              </div>
            </div>

            <div className="space-y-4">
              <label className="text-sm font-medium text-gray-700">
                {t('calculator.commissionOffer.label')}
              </label>
              <Slider
                defaultValue={[commission]}
                max={5}
                min={1}
                step={0.1}
                onValueChange={(value) => setCommission(value[0])}
                className="w-full"
              />
              <div className="text-right text-lg font-semibold">
                {commission.toFixed(2)}%
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center space-y-6">
            <div className="relative w-full max-w-[300px] h-[150px] flex justify-center">
              <PieChart width={300} height={150}>
                <Pie
                  data={pieData}
                  cx="50%"
                  cy="100%"
                  startAngle={180}
                  endAngle={0}
                  innerRadius={60}
                  outerRadius={100}
                  paddingAngle={0}
                  dataKey="value"
                >
                  {pieData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index]} />
                  ))}
                </Pie>
              </PieChart>
              <div className="absolute bottom-4 left-0 w-full px-6 flex justify-between text-xs">
                <span className="text-[#1B365D] font-medium">
                  {new Intl.NumberFormat('fi-FI', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(ourCommissionAmount)}
                </span>
                <span className="text-green-600 font-medium">
                  {new Intl.NumberFormat('fi-FI', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(propertyValue * 0.04)}
                </span>
              </div>
            </div>

            <div className="text-center">
              <div className="text-5xl font-bold text-[#1B365D]">
                {new Intl.NumberFormat('fi-FI', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(savings)}
              </div>
              <p className="mt-2 text-sm text-gray-600">
                {t('calculator.savings.label')}
              </p>
              <p className="mt-4 text-xs text-gray-500 italic">
                {t('calculator.savings.disclaimer')}
              </p>
            </div>

            <Button 
              className="w-full bg-[#1B365D] hover:bg-[#152C4A] text-white py-6 text-lg"
              onClick={handleStartComparison}
            >
              {t('calculator.startComparison')}
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};
