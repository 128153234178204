
import { FileEdit, Search, Home } from "lucide-react";
import { useTranslation } from "react-i18next";

export function HowItWorks() {
  const { t } = useTranslation();
  
  return (
    <section className="py-12">
      <div className="max-w-3xl mx-auto">
        <h2 className="text-3xl font-bold text-white text-center mb-12">
          {t('hintaArvio.howItWorks.title')}
        </h2>
        
        <div className="grid md:grid-cols-3 gap-8 relative">
          {/* Connecting line for desktop */}
          <div className="hidden md:block absolute top-12 left-[20%] right-[20%] h-0.5 bg-white/20" />
          
          <div className="flex flex-col items-center text-center group">
            <div className="w-24 h-24 bg-white/10 rounded-full flex items-center justify-center mb-4 shadow-lg shadow-black/20 transition-transform duration-300 group-hover:scale-110">
              <FileEdit className="w-10 h-10 text-white" />
            </div>
            <span className="px-4 py-1 bg-white/10 text-white text-sm rounded-full mb-4 shadow-md">
              {t('step')} 1
            </span>
            <h3 className="text-xl font-semibold text-white mb-2">
              {t('hintaArvio.howItWorks.steps.step1.title')}
            </h3>
            <p className="text-white/80 px-4">
              {t('hintaArvio.howItWorks.steps.step1.description')}
            </p>
          </div>

          <div className="flex flex-col items-center text-center group">
            <div className="w-24 h-24 bg-white/10 rounded-full flex items-center justify-center mb-4 shadow-lg shadow-black/20 transition-transform duration-300 group-hover:scale-110">
              <Search className="w-10 h-10 text-white" />
            </div>
            <span className="px-4 py-1 bg-white/10 text-white text-sm rounded-full mb-4 shadow-md">
              {t('step')} 2
            </span>
            <h3 className="text-xl font-semibold text-white mb-2">
              {t('hintaArvio.howItWorks.steps.step2.title')}
            </h3>
            <p className="text-white/80 px-4">
              {t('hintaArvio.howItWorks.steps.step2.description')}
            </p>
          </div>

          <div className="flex flex-col items-center text-center group">
            <div className="w-24 h-24 bg-white/10 rounded-full flex items-center justify-center mb-4 shadow-lg shadow-black/20 transition-transform duration-300 group-hover:scale-110">
              <Home className="w-10 h-10 text-white" />
            </div>
            <span className="px-4 py-1 bg-white/10 text-white text-sm rounded-full mb-4 shadow-md">
              {t('step')} 3
            </span>
            <h3 className="text-xl font-semibold text-white mb-2">
              {t('hintaArvio.howItWorks.steps.step3.title')}
            </h3>
            <p className="text-white/80 px-4">
              {t('hintaArvio.howItWorks.steps.step3.description')}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
