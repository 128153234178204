
import { Phone, Mail, Globe, Building2 } from "lucide-react";
import { useTranslation } from "react-i18next";

export const ContactSection = () => {
  const { t } = useTranslation();
  
  return (
    <section className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12">{t('forAgents.contact.title')}</h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          <div className="text-center">
            <div className="w-12 h-12 bg-[#1B365D]/10 rounded-full flex items-center justify-center mx-auto mb-4">
              <Phone className="h-6 w-6 text-[#1B365D]" />
            </div>
            <div className="mb-2">
              <a href="tel:+358468000085" className="text-[#1B365D] hover:underline">
                {t('forAgents.contact.phone.number')}
              </a>
            </div>
            <p className="text-sm text-gray-600">{t('forAgents.contact.phone.label')}</p>
          </div>

          <div className="text-center">
            <div className="w-12 h-12 bg-[#1B365D]/10 rounded-full flex items-center justify-center mx-auto mb-4">
              <Mail className="h-6 w-6 text-[#1B365D]" />
            </div>
            <div className="mb-2">
              <a href="mailto:asiakaspalvelu@salaovi.fi" className="text-[#1B365D] hover:underline">
                {t('forAgents.contact.email.address')}
              </a>
            </div>
            <p className="text-sm text-gray-600">{t('forAgents.contact.email.label')}</p>
          </div>

          <div className="text-center">
            <div className="w-12 h-12 bg-[#1B365D]/10 rounded-full flex items-center justify-center mx-auto mb-4">
              <Globe className="h-6 w-6 text-[#1B365D]" />
            </div>
            <div className="mb-2">
              <a href="https://partners.salaovi.fi" target="_blank" rel="noopener noreferrer" className="text-[#1B365D] hover:underline">
                {t('forAgents.contact.platform.url')}
              </a>
            </div>
            <p className="text-sm text-gray-600">{t('forAgents.contact.platform.label')}</p>
          </div>

          <div className="text-center">
            <div className="w-12 h-12 bg-[#1B365D]/10 rounded-full flex items-center justify-center mx-auto mb-4">
              <Building2 className="h-6 w-6 text-[#1B365D]" />
            </div>
            <div className="mb-2">
              <p className="text-[#1B365D]">{t('forAgents.contact.office.address.line1')}</p>
              <p className="text-[#1B365D]">{t('forAgents.contact.office.address.line2')}</p>
            </div>
            <p className="text-sm text-gray-600">{t('forAgents.contact.office.label')}</p>
          </div>
        </div>
      </div>
    </section>
  );
};
