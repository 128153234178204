
import { Card } from "@/components/ui/card";
import { useTranslation } from "react-i18next";
import { Star } from "lucide-react";

interface Testimonial {
  name: string;
  location: string;
  text: string;
}

export const Reviews = () => {
  const { t } = useTranslation();

  // Get testimonials and ensure it's an array
  const testimonials = t('reviews.testimonials', { returnObjects: true }) || [];
  const testimonialsList = Array.isArray(testimonials) ? testimonials : [];

  return (
    <section className="py-20 px-4 bg-gradient-to-br from-[#E6F4F1] to-white">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-16 animate-fade-in">
          <h2 className="text-3xl md:text-4xl font-bold mb-6 text-[#1B365D]">
            {t('reviews.title')}
          </h2>
          <div className="flex justify-center items-center gap-1 mb-4">
            {[...Array(5)].map((_, i) => (
              <Star key={i} className="w-6 h-6 fill-[#1B365D] text-[#1B365D]" />
            ))}
          </div>
        </div>
        
        <div className="grid md:grid-cols-3 gap-8">
          {testimonialsList.map((review, index) => (
            <Card 
              key={index} 
              className="p-8 hover:shadow-xl transition-all duration-300 animate-fade-in bg-white/80 backdrop-blur-sm border border-gray-100" 
              style={{ animationDelay: `${index * 200}ms` }}
            >
              <div className="flex items-center gap-1 mb-4">
                {[...Array(5)].map((_, i) => (
                  <Star key={i} className="w-4 h-4 fill-[#1B365D] text-[#1B365D]" />
                ))}
              </div>
              <p className="text-gray-700 mb-6 text-lg leading-relaxed">"{review.text}"</p>
              <div className="text-[#1B365D]">
                <p className="font-semibold text-lg">{review.name}</p>
                <p className="text-sm opacity-75">{review.location}</p>
              </div>
            </Card>
          ))}
        </div>
      </div>
    </section>
  );
};
