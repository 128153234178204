
import { useTranslation } from "react-i18next";

export const StatsSection = () => {
  const { t } = useTranslation();

  return (
    <section className="py-16 bg-white">
      <div className="container mx-auto px-4">
        <div className="grid md:grid-cols-3 gap-8">
          <div className="text-center">
            <div className="text-4xl font-bold text-[#1B365D] mb-2">{t('forAgents.stats.yearsInMarket.value')}</div>
            <p className="text-gray-600">{t('forAgents.stats.yearsInMarket.label')}</p>
          </div>
          <div className="text-center">
            <div className="text-4xl font-bold text-[#1B365D] mb-2">{t('forAgents.stats.comparisons.value')}</div>
            <p className="text-gray-600">{t('forAgents.stats.comparisons.label')}</p>
          </div>
          <div className="text-center">
            <div className="text-4xl font-bold text-[#1B365D] mb-2">{t('forAgents.stats.agents.value')}</div>
            <p className="text-gray-600">{t('forAgents.stats.agents.label')}</p>
          </div>
        </div>
      </div>
    </section>
  );
};
