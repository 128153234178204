
import { useEffect, useRef, useState } from "react";
import { Button } from "@/components/ui/button";
import { Euro, ArrowRight, Home, MapPin, Building2, ActivitySquare, Clock, ArrowDown } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useForm } from "@/contexts/FormContext";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { useTranslation } from "react-i18next";

interface PriceResultProps {
  estimatedPrice: number;
}

export function PriceResult({ estimatedPrice }: PriceResultProps) {
  const navigate = useNavigate();
  const { formData } = useForm();
  const { toast } = useToast();
  const notificationSent = useRef(false);
  const [isCalculating, setIsCalculating] = useState(false);
  const { t } = useTranslation();
  
  const standardFee = estimatedPrice * 0.048; // 4.8%
  const reducedFee = estimatedPrice * 0.025; // 2.5%
  const potentialSavings = standardFee - reducedFee;

  const formatPropertyType = (type: string) => {
    return t(`hintaArvio.form.propertyDetails.propertyType.${type}`);
  };

  const formatCondition = (condition: string) => {
    return t(`hintaArvio.form.propertyDetails.condition.${condition}`);
  };

  const formatSalesTimeline = (timeline?: string) => {
    return timeline ? t(`hintaArvio.form.contact.timeline.options.${timeline}`) : '';
  };

  useEffect(() => {
    const sendEstimate = async () => {
      if (notificationSent.current || isCalculating || !formData.email || !formData.phone) {
        return;
      }

      setIsCalculating(true);
      
      try {
        // Send estimate via Supabase function
        const { error: supabaseError } = await supabase.functions.invoke('send-estimate', {
          body: {
            customerEmail: formData.email,
            adminEmail: 'tuki@salaovi.fi',
            propertyDetails: {
              address: formData.address?.address || '',
              postalCode: formData.address?.components.postal_code || '',
              city: formData.address?.components.locality || '',
              size: formData.size || '',
              condition: formData.condition || 'average',
              propertyType: formData.propertyType || 'apartment',
              buildYear: formData.buildYear || '',
              estimatedPrice: estimatedPrice,
              salesTimeline: formData.salesTimeline || 'not_specified'
            },
            contact: {
              email: formData.email,
              phone: formData.phone
            }
          }
        });

        if (supabaseError) {
          throw supabaseError;
        }

        // Format data for Make.com webhook
        const webhookData = {
          address: formData.address?.address,
          postalCode: formData.address?.components.postal_code || '',
          city: formData.address?.components.locality || '',
          size: formData.size || '',
          condition: formData.condition || 'average',
          propertyType: formData.propertyType || 'apartment',
          buildYear: formData.buildYear || '',
          email: formData.email,
          phone: formData.phone,
          estimatedPrice: new Intl.NumberFormat('fi-FI').format(estimatedPrice),
          salesTimeline: formData.salesTimeline || 'not_specified'
        };

        // Send to Make.com webhook
        const webhookResponse = await fetch("https://hook.eu2.make.com/26b2v1x1878d07lyx6mcdct6jjhq998w", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify(webhookData),
        });

        if (!webhookResponse.ok) {
          throw new Error(`Webhook error: ${await webhookResponse.text()}`);
        }

        notificationSent.current = true;
        toast({
          title: t('success.title', 'Onnistui'),
          description: t('success.emailSent', 'Hinta-arvio lähetetty sähköpostiisi'),
        });
      } catch (error) {
        console.error('Error sending notifications:', error);
        toast({
          title: t('error.title', 'Virhe'),
          description: t('error.generic', 'Hinta-arvion lähettämisessä tapahtui virhe'),
          variant: "destructive",
        });
      } finally {
        setIsCalculating(false);
      }
    };

    sendEstimate();
  }, [estimatedPrice, formData, toast, t]); 

  return (
    <div className="space-y-8 p-6">
      <div className="text-center space-y-4">
        <div className="inline-flex items-center justify-center gap-2 px-4 py-2 bg-[#1B365D]/10 rounded-full">
          <Home className="h-5 w-5 text-[#1B365D]" />
          <span className="text-[#1B365D] font-medium">{t('hintaArvio.result.title')}</span>
        </div>
        <div className="flex items-center justify-center gap-2 text-4xl font-bold text-[#1B365D]">
          <Euro className="h-8 w-8" />
          {new Intl.NumberFormat('fi-FI').format(estimatedPrice)}
        </div>

        {/* Property Details */}
        <div className="flex justify-center gap-4 mt-4">
          <div className="inline-flex items-center gap-2 px-4 py-2 bg-[#1B365D] text-white rounded-full">
            <Building2 className="h-4 w-4" />
            <span>{formatPropertyType(formData.propertyType || 'apartment')}</span>
          </div>
          <div className="inline-flex items-center gap-2 px-4 py-2 bg-[#E6F4F1] text-[#1B365D] rounded-full">
            <ActivitySquare className="h-4 w-4" />
            <span>{t('condition')}: {formatCondition(formData.condition || 'average')}</span>
          </div>
        </div>

        {/* Address Details */}
        <div className="mt-6 p-4 bg-gray-50 rounded-lg">
          <div className="flex items-center justify-center gap-2 text-gray-600">
            <MapPin className="h-4 w-4" />
            <span>{formData.address?.address}</span>
          </div>
          <div className="mt-2 text-sm text-gray-500">
            {formData.size} m² • {formData.buildYear}
          </div>
        </div>

        {/* Fee Comparison Section */}
        <div className="mt-8 space-y-6">
          <h3 className="text-xl font-semibold text-[#1B365D]">
            {t('hintaArvio.result.comparison.title')}
          </h3>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Standard Fee */}
            <div className="p-4 bg-gray-50 rounded-lg">
              <div className="text-gray-600 mb-2">{t('hintaArvio.result.comparison.standardFee.title')}</div>
              <div className="text-2xl font-bold text-[#1B365D]">
                {new Intl.NumberFormat('fi-FI').format(Math.round(standardFee))} €
              </div>
            </div>

            {/* Reduced Fee */}
            <div className="p-4 bg-[#E6F4F1] rounded-lg">
              <div className="text-[#1B365D] mb-2">{t('hintaArvio.result.comparison.reducedFee.title')}</div>
              <div className="text-2xl font-bold text-[#1B365D]">
                {new Intl.NumberFormat('fi-FI').format(Math.round(reducedFee))} €
              </div>
            </div>
          </div>

          {/* Potential Savings */}
          <div className="p-6 bg-[#1B365D] rounded-lg text-white">
            <div className="flex items-center justify-center gap-2">
              <ArrowDown className="h-6 w-6" />
              <div className="text-lg">{t('hintaArvio.result.comparison.potentialSavings.title')}</div>
            </div>
            <div className="text-3xl font-bold mt-2">
              {new Intl.NumberFormat('fi-FI').format(Math.round(potentialSavings))} €
            </div>
          </div>
        </div>

        {/* Call to Action */}
        <div className="mt-8 space-y-4">
          <p className="text-gray-600">
            {t('hintaArvio.result.cta.description')}
          </p>
          
          <Button 
            onClick={() => navigate('/')} 
            className="w-full h-12 text-lg bg-[#1B365D] hover:bg-[#1B365D]/90"
          >
            {t('hintaArvio.result.cta.button')}
            <ArrowRight className="h-5 w-5 ml-2" />
          </Button>
        </div>

        {/* Disclaimer */}
        <div className="mt-8 space-y-4">
          <div className="p-4 bg-white/50 rounded-lg">
            <p className="text-sm text-gray-500">{t('hintaArvio.result.disclaimer.estimate')}</p>
          </div>
          
          <div className="flex items-center justify-center gap-2 text-sm text-gray-500">
            <Clock className="h-4 w-4" />
            <span>{t('hintaArvio.result.disclaimer.created')}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
