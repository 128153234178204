
import { useEffect, useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { format } from "date-fns";
import { AnimatePresence, motion } from "framer-motion";
import { Building2, MapPin, Home } from "lucide-react";

interface Enquiry {
  id: string;
  created_at: string;
  address: string;
  city: string;
  postal_code: string;
  first_name: string;
  image_url: string;
  size: string;
}

export const LiveSalesPopup = () => {
  const [enquiries, setEnquiries] = useState<Enquiry[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    // Fetch initial enquiries
    const fetchEnquiries = async () => {
      console.log('Fetching enquiries...');
      try {
        const { data, error } = await supabase
          .from('enquiries')
          .select('id, created_at, address, city, postal_code, first_name, image_url, size')
          .order('created_at', { ascending: false })
          .limit(5);

        console.log('Supabase response:', { data, error });

        if (error) {
          console.error('Error fetching enquiries:', error);
          return;
        }

        if (data) {
          console.log('Setting enquiries:', data);
          setEnquiries(data);
        }
      } catch (error) {
        console.error('Exception while fetching enquiries:', error);
      }
    };

    fetchEnquiries();

    // Subscribe to new enquiries
    const channel = supabase
      .channel('realtime-enquiries')
      .on(
        'postgres_changes',
        {
          event: 'INSERT',
          schema: 'public',
          table: 'enquiries'
        },
        (payload) => {
          console.log('Received new enquiry:', payload);
          setEnquiries(prev => [payload.new as Enquiry, ...prev.slice(0, 4)]);
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, []);

  useEffect(() => {
    if (enquiries.length === 0) return;

    const interval = setInterval(() => {
      setCurrentIndex((prev) => (prev + 1) % enquiries.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [enquiries.length]);

  if (enquiries.length === 0) return null;

  const currentEnquiry = enquiries[currentIndex];
  if (!currentEnquiry) return null;

  const timeAgo = (date: string) => {
    const now = new Date();
    const enquiryDate = new Date(date);
    const diffInMinutes = Math.floor((now.getTime() - enquiryDate.getTime()) / (1000 * 60));

    if (diffInMinutes < 1) return 'Just now';
    if (diffInMinutes < 60) return `${diffInMinutes}m ago`;
    if (diffInMinutes < 1440) return `${Math.floor(diffInMinutes / 60)}h ago`;
    return format(enquiryDate, 'MMM d');
  };

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={currentEnquiry.id}
        initial={{ opacity: 0, x: -100 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -100 }}
        className="fixed bottom-4 left-4 z-50 max-w-sm bg-white rounded-lg shadow-lg border border-gray-200"
      >
        <div className="flex items-start p-4 gap-4">
          <div className="relative h-16 w-16 flex-shrink-0">
            {currentEnquiry.image_url ? (
              <img
                src={currentEnquiry.image_url}
                alt="Property"
                className="h-full w-full object-cover rounded-md"
              />
            ) : (
              <div className="h-full w-full bg-gray-100 rounded-md flex items-center justify-center">
                <Building2 className="h-8 w-8 text-gray-400" />
              </div>
            )}
          </div>
          <div className="flex-1 min-w-0">
            <div className="flex items-start justify-between">
              <div className="flex-1">
                <div className="flex items-center gap-1 text-sm text-gray-600 mb-1">
                  <MapPin className="h-4 w-4" />
                  {currentEnquiry.city || 'Location'}
                </div>
                <p className="text-sm font-medium text-gray-900 truncate">
                  {currentEnquiry.address}
                </p>
                <div className="flex items-center gap-2 mt-1">
                  <div className="flex items-center gap-1 text-xs text-gray-500">
                    <Home className="h-3 w-3" />
                    {currentEnquiry.size} m²
                  </div>
                  <span className="text-xs text-gray-500">•</span>
                  <p className="text-xs text-gray-500">
                    {timeAgo(currentEnquiry.created_at)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};
