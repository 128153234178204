
import { Button } from "@/components/ui/button";
import { ArrowRight, Users, Timer, PiggyBank, ThumbsUp } from "lucide-react";
import { AddressInput } from "@/components/AddressInput";
import { ContactForm } from "@/components/ContactForm";
import { OtpVerification } from "@/components/OtpVerification";
import { ThankYou } from "@/components/ThankYou";
import { StreetView } from "@/components/StreetView";
import { LogoCarousel } from "@/components/LogoCarousel";
import { useToast } from "@/hooks/use-toast";
import { useForm } from "@/contexts/FormContext";
import { useTranslation } from "react-i18next";
import type { PlaceResult } from "@/hooks/useGoogleMaps";

export const Hero = () => {
  const { toast } = useToast();
  const { step, setStep, updateFormData, formData } = useForm();
  const { t } = useTranslation();

  const handleAddressSelect = (place: PlaceResult) => {
    console.log("Selected address:", place);
    if (!place.components.street_number) {
      toast({
        title: "Virheellinen osoite",
        description: "Valitse tarkka katuosoite numeroineen",
        variant: "destructive",
      });
      return;
    }
    updateFormData({ address: place });
  };

  const handleCompare = () => {
    if (!formData.address?.components.street_number) {
      toast({
        title: "Virheellinen osoite",
        description: "Valitse tarkka katuosoite numeroineen",
        variant: "destructive",
      });
      return;
    }
    setStep(2);
    toast({
      title: "Osoite valittu",
      description: formData.address.address,
    });
  };

  const handleOtpVerificationSuccess = () => {
    setStep(4);
  };

  return (
    <section 
      className="relative min-h-[85vh] sm:min-h-screen flex items-center justify-center pt-24 sm:pt-32 pb-8 sm:pb-24 overflow-hidden"
      style={{
        backgroundImage: `url('/lovable-uploads/6d9c45ac-6283-4e43-ba1b-c6595962a6ba.png')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <div className="absolute inset-0 bg-black/60 sm:bg-black/40 backdrop-blur-[2px]" />

      <div className="relative z-10 w-full mx-auto text-center">
        {step < 4 && (
          <div className="space-y-4 sm:space-y-8 mb-6 sm:mb-12 max-w-5xl mx-auto px-4">
            <h1 className="text-2xl sm:text-4xl lg:text-6xl font-bold tracking-tight text-white leading-tight">
              {t('hero.title')}{" "}
              <span className="text-[#0EA5E9]">{t('hero.titleHighlight')}</span>
            </h1>
            <p className="text-sm sm:text-xl text-white/90 max-w-4xl mx-auto">
              {t('hero.description')}
            </p>
          </div>
        )}

        {step === 1 && (
          <>
            <div className="space-y-6 bg-white/95 backdrop-blur-sm p-4 sm:p-8 lg:p-10 rounded-xl shadow-2xl border border-white/20 max-w-3xl mx-auto mb-6 sm:mb-8 mx-3 sm:mx-auto">
              <div className="flex items-center gap-3 mb-2 sm:mb-6">
                <div className="h-8 w-8 sm:h-10 sm:w-10 rounded-full bg-green-100 flex items-center justify-center">
                  <ArrowRight className="w-4 h-4 sm:w-5 sm:h-5 text-green-600" />
                </div>
                <p className="text-sm sm:text-base font-medium text-gray-700">
                  {t('hero.startWriting')}
                </p>
              </div>
              <AddressInput 
                onAddressSelect={handleAddressSelect}
                className="w-full px-4 py-3 sm:px-6 sm:py-4 rounded-lg border border-gray-200 focus:outline-none focus:ring-2 focus:ring-[#0EA5E9] shadow-sm text-base"
              />
              <Button 
                onClick={handleCompare}
                className="w-full bg-[#1B365D] hover:bg-[#152C4A] text-white px-4 py-5 sm:px-8 sm:py-7 rounded-lg text-base sm:text-lg transition-all duration-300 flex items-center justify-center gap-2 shadow-lg hover:shadow-xl"
              >
                {t('hero.startComparison')}
                <ArrowRight className="w-4 h-4 sm:w-5 sm:h-5" />
              </Button>

              <div className="pt-4 sm:pt-6 border-t border-gray-200 hidden sm:block">
                <div className="grid grid-cols-2 md:grid-cols-4 gap-3 sm:gap-4 text-sm text-gray-600">
                  <div>
                    <div className="flex justify-center mb-2">
                      <Users className="w-5 h-5 text-[#0EA5E9]" />
                    </div>
                    <p className="font-semibold text-gray-500">{t('hero.stats.agents.count')}</p>
                    <p>{t('hero.stats.agents.label')}</p>
                  </div>
                  <div>
                    <div className="flex justify-center mb-2">
                      <Timer className="w-5 h-5 text-[#0EA5E9]" />
                    </div>
                    <p className="font-semibold text-gray-500">{t('hero.stats.responseTime.count')}</p>
                    <p>{t('hero.stats.responseTime.label')}</p>
                  </div>
                  <div>
                    <div className="flex justify-center mb-2">
                      <PiggyBank className="w-5 h-5 text-[#0EA5E9]" />
                    </div>
                    <p className="font-semibold text-gray-500">{t('hero.stats.savings.count')}</p>
                    <p>{t('hero.stats.savings.label')}</p>
                  </div>
                  <div>
                    <div className="flex justify-center mb-2">
                      <ThumbsUp className="w-5 h-5 text-[#0EA5E9]" />
                    </div>
                    <p className="font-semibold text-gray-500">{t('hero.stats.satisfaction.count')}</p>
                    <p>{t('hero.stats.satisfaction.label')}</p>
                  </div>
                </div>
              </div>
            </div>
            
            {/* Mobile only logos */}
            <div className="block sm:hidden mx-auto px-4 my-6">
              <img 
                src="/lovable-uploads/34aeca3d-22be-4f9b-ab43-a9009a3dc2b9.png" 
                alt="Kiinteistönvälittäjien logot" 
                className="w-full max-w-md mx-auto"
              />
            </div>
            
            <div className="w-full hidden sm:block">
              <LogoCarousel />
            </div>
          </>
        )}

        {step === 2 && (
          <div className="bg-white/95 backdrop-blur-sm p-4 sm:p-8 lg:p-10 rounded-xl shadow-2xl border border-white/20 max-w-3xl mx-auto mx-3 sm:mx-auto">
            {formData.address && (
              <div className="mb-8">
                <StreetView className="mb-2 rounded-lg overflow-hidden" />
                <p className="text-sm text-gray-600 bg-[#F1F0FB] p-3 rounded-lg">
                  {t('contactForm.streetView.info')}
                </p>
              </div>
            )}
            <ContactForm />
          </div>
        )}

        {step === 3 && (
          <div className="bg-white/95 backdrop-blur-sm p-4 sm:p-8 lg:p-10 rounded-xl shadow-2xl border border-white/20 max-w-3xl mx-auto mx-3 sm:mx-auto">
            <OtpVerification onSuccess={handleOtpVerificationSuccess} />
          </div>
        )}

        {step === 4 && (
          <div className="bg-white/95 backdrop-blur-sm p-4 sm:p-8 lg:p-10 rounded-xl shadow-2xl border border-white/20 max-w-3xl mx-auto mx-3 sm:mx-auto">
            <ThankYou />
          </div>
        )}
      </div>

      <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent pointer-events-none" />
      <div className="absolute inset-0 bg-gradient-to-b from-black/30 to-transparent pointer-events-none" />
    </section>
  );
};
