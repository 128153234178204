
import { Card } from "@/components/ui/card";
import { Smartphone, Search, FileText } from "lucide-react";
import { useTranslation } from "react-i18next";

const services = [
  {
    icon: Smartphone,
    translationKey: "start"
  },
  {
    icon: Search,
    translationKey: "compare"
  },
  {
    icon: FileText,
    translationKey: "choose"
  }
];

export const Services = () => {
  const { t } = useTranslation();

  return (
    <section className="py-20 px-4 bg-white">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-16 animate-fade-in">
          <h2 className="text-3xl md:text-4xl font-bold mb-4 text-[#1B365D]">
            {t('services.title')}
          </h2>
        </div>
        <div className="grid md:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <Card key={index} className="p-6 text-center hover:shadow-lg transition-shadow duration-300 animate-fade-in border-none" style={{ animationDelay: `${index * 200}ms` }}>
              <div className="flex justify-center mb-4">
                <service.icon className="w-16 h-16 text-[#1B365D]" />
              </div>
              <h3 className="text-xl font-semibold mb-4 text-[#1B365D]">
                {t(`services.steps.${service.translationKey}.title`)}
              </h3>
              <p className="text-gray-600">
                {t(`services.steps.${service.translationKey}.description`)}
              </p>
            </Card>
          ))}
        </div>
      </div>
    </section>
  );
};
