
import { Toaster } from "@/components/ui/toaster";
import { TooltipProvider } from "@/components/ui/tooltip";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Navbar } from "@/components/Navbar";
import { Footer } from "@/components/Footer";
import VälittäjäVertailu from "./pages/Index";
import Blog from "./pages/Blog";
import BlogPost from "./pages/BlogPost";
import NotFound from "./pages/NotFound";
import PrivacyPolicy from "./pages/tietosuojakaytanto";
import TermsOfService from "./pages/palvelun-kayttoehdot";
import ValittajillePage from "./pages/valittajille";
import PriceEstimation from "./pages/hinta-arvio";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const queryClient = new QueryClient();

// Helper to get default language path
const getDefaultLanguagePath = () => {
  const browserLang = navigator.language.split('-')[0];
  const validLangs = ['fi', 'sv', 'en', 'et', 'de'];
  const lang = validLangs.includes(browserLang) ? browserLang : 'fi';
  return `/${lang}/${lang === 'fi' ? 'fin' : lang === 'sv' ? 'swe' : lang === 'en' ? 'eng' : lang === 'et' ? 'est' : 'ger'}`;
};

// Helper to get language path with current route
const getLanguagePathWithRoute = (route: string) => {
  const browserLang = navigator.language.split('-')[0];
  const validLangs = ['fi', 'sv', 'en', 'et', 'de'];
  const lang = validLangs.includes(browserLang) ? browserLang : 'fi';
  return `/${lang}/${lang === 'fi' ? 'fin' : lang === 'sv' ? 'swe' : lang === 'en' ? 'eng' : lang === 'et' ? 'est' : 'ger'}${route}`;
};

const App = () => {
  const { i18n } = useTranslation();

  // Redirect root and direct URLs to language path on first load
  useEffect(() => {
    const path = window.location.pathname;
    // If root path or direct URL without language prefix
    if (path === '/' || !path.match(/^\/[a-z]{2}\/(fin|swe|eng|est|ger)/)) {
      const directRoutes = {
        '/hinta-arvio': true,
        '/blogi': true,
        '/valittajille': true,
        '/tietosuojakaytanto': true,
        '/palvelun-kayttoehdot': true,
        '/valittajavertailu': true
      };
      
      // If it's a known route, redirect to language path with route
      if (directRoutes[path as keyof typeof directRoutes]) {
        window.location.replace(getLanguagePathWithRoute(path));
      } else if (path === '/') {
        // If root, redirect to default language path
        window.location.replace(getDefaultLanguagePath());
      }
      // If unknown route without language, will be caught by NotFound
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <TooltipProvider>
        <Toaster />
        <BrowserRouter>
          <div className="flex flex-col min-h-screen">
            <Navbar />
            <main className="flex-grow">
              <Routes>
                {/* Root redirect */}
                <Route path="/" element={<Navigate to={getDefaultLanguagePath()} replace />} />

                {/* Direct route redirects */}
                <Route path="/hinta-arvio" element={<Navigate to={getLanguagePathWithRoute('/hinta-arvio')} replace />} />
                <Route path="/blogi" element={<Navigate to={getLanguagePathWithRoute('/blogi')} replace />} />
                <Route path="/blogi/:slug" element={<Navigate to={getLanguagePathWithRoute('/blogi')} replace />} />
                <Route path="/valittajille" element={<Navigate to={getLanguagePathWithRoute('/valittajille')} replace />} />
                <Route path="/tietosuojakaytanto" element={<Navigate to={getLanguagePathWithRoute('/tietosuojakaytanto')} replace />} />
                <Route path="/palvelun-kayttoehdot" element={<Navigate to={getLanguagePathWithRoute('/palvelun-kayttoehdot')} replace />} />
                <Route path="/valittajavertailu" element={<Navigate to={getLanguagePathWithRoute('/valittajavertailu')} replace />} />

                {/* Language specific routes */}
                {['fi/fin', 'sv/swe', 'en/eng', 'et/est', 'de/ger'].map((langPath) => (
                  <Route key={langPath} path={`/${langPath}/*`} element={
                    <Routes>
                      <Route path="/" element={<VälittäjäVertailu />} />
                      <Route path="/valittajavertailu" element={<VälittäjäVertailu />} />
                      <Route path="/hinta-arvio" element={<PriceEstimation />} />
                      <Route path="/blogi" element={<Blog />} />
                      <Route path="/blogi/:slug" element={<BlogPost />} />
                      <Route path="/valittajille" element={<ValittajillePage />} />
                      <Route path="/tietosuojakaytanto" element={<PrivacyPolicy />} />
                      <Route path="/palvelun-kayttoehdot" element={<TermsOfService />} />
                      <Route path="*" element={<NotFound />} />
                    </Routes>
                  } />
                ))}

                {/* Catch all route for 404 */}
                <Route path="*" element={<NotFound />} />
              </Routes>
            </main>
            <Footer />
          </div>
        </BrowserRouter>
      </TooltipProvider>
    </QueryClientProvider>
  );
};

export default App;
