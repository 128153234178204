
import { useEffect, useRef } from "react";

const LOGOS = [
  {
    src: "/lovable-uploads/b9016697-bc4b-4110-919a-5de0a4c6e23f.png",
    alt: "Remax Logo"
  },
  {
    src: "/lovable-uploads/56817e29-029d-484f-8570-d8308152834b.png",
    alt: "Premier LKV Logo"
  },
  {
    src: "/lovable-uploads/0296bef9-3b0d-4a00-a862-ec817fdb5edd.png",
    alt: "Neliöt Liikkuu Logo"
  },
  {
    src: "/lovable-uploads/b9917d17-136f-443d-9fb8-7762af977644.png",
    alt: "Muuttos LKV Logo"
  },
  {
    src: "/lovable-uploads/640501a3-e3b3-4588-bc09-7a29fc593ee6.png",
    alt: "Roof LKV Logo"
  },
  {
    src: "/lovable-uploads/d8a65039-637c-4484-9b7a-162fd508aae4.png",
    alt: "Bo LKV Logo"
  },
  {
    src: "/lovable-uploads/d05712c4-c7ba-421b-a6bf-957073afc0ba.png",
    alt: "Kiinteistömaailma Logo"
  },
  {
    src: "/lovable-uploads/80b0ba9b-f66b-4640-9c3f-07e628fc1986.png",
    alt: "Huoneisto Ketju Logo"
  },
  {
    src: "/lovable-uploads/5ada5aa4-7594-43d9-9914-3b52ccba7de9.png",
    alt: "Premier Logo"
  },
  {
    src: "/lovable-uploads/bbe1ffd8-58bb-4bc9-b934-db3dfd034e3b.png",
    alt: "Habita Logo"
  }
];

export const LogoCarousel = () => {
  const scrollRef = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    const scrollContainer = scrollRef.current;
    if (!scrollContainer) return;

    const scroll = () => {
      if (scrollContainer.scrollLeft >= scrollContainer.scrollWidth - scrollContainer.clientWidth) {
        scrollContainer.scrollLeft = 0;
      } else {
        scrollContainer.scrollLeft += 1;
      }
    };

    const intervalId = setInterval(scroll, 30);
    
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="w-full overflow-hidden bg-white/10 py-6">
      <div
        ref={scrollRef}
        className="flex gap-12 overflow-hidden whitespace-nowrap"
        style={{ 
          maskImage: 'linear-gradient(to right, transparent, black 5%, black 95%, transparent)',
          WebkitMaskImage: 'linear-gradient(to right, transparent, black 5%, black 95%, transparent)'
        }}
      >
        {/* Duplicate logos for seamless scrolling */}
        {[...LOGOS, ...LOGOS, ...LOGOS].map((logo, index) => (
          <img
            key={index}
            src={logo.src}
            alt={logo.alt}
            className="h-12 w-auto object-contain inline-block"
          />
        ))}
      </div>
    </div>
  );
};
