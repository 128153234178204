
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { ArrowLeft, Tag } from "lucide-react";

export const BlogPost = () => {
  const { slug } = useParams();
  
  const getImageUrl = (url: string | null) => {
    if (!url) return null;
    if (url.startsWith('http')) return url;
    return `https://yfhzflexahlxpaogypbx.supabase.co/storage/v1/object/public/blog_images/${url}`;
  };

  const { data: post, isLoading, error } = useQuery({
    queryKey: ['blogPost', slug],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('blog_posts')
        .select(`
          *,
          categories:blog_posts_categories(
            category:categories(
              name,
              slug
            )
          )
        `)
        .eq('slug', slug)
        .single();
        
      if (error) throw error;
      
      // Transform the categories data structure
      const transformedData = {
        ...data,
        categories: data.categories?.map((c: any) => c.category)
      };
      
      return transformedData;
    },
  });

  if (isLoading) {
    return (
      <div className="pt-20 min-h-screen bg-white">
        <div className="max-w-3xl mx-auto px-4">
          <div className="animate-pulse">
            <div className="h-48 bg-gray-200 rounded w-full mb-4"></div>
            <div className="h-8 bg-gray-200 rounded w-3/4 mb-4"></div>
            <div className="h-4 bg-gray-200 rounded w-1/4 mb-8"></div>
            <div className="space-y-4">
              <div className="h-4 bg-gray-200 rounded w-full"></div>
              <div className="h-4 bg-gray-200 rounded w-full"></div>
              <div className="h-4 bg-gray-200 rounded w-3/4"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error || !post) {
    return (
      <div className="pt-20 min-h-screen bg-white">
        <div className="max-w-3xl mx-auto px-4 text-center">
          <h1 className="text-2xl font-bold text-red-500">Artikkelia ei löytynyt</h1>
        </div>
      </div>
    );
  }

  return (
    <div className="pt-20 min-h-screen bg-gradient-to-b from-white to-gray-50">
      <article className="max-w-3xl mx-auto px-4 py-12">
        <Link to="/blogi">
          <Button variant="ghost" className="mb-8 -ml-2">
            <ArrowLeft className="mr-2 h-4 w-4" />
            Takaisin blogiin
          </Button>
        </Link>
        {post.featured_image && (
          <div className="aspect-video mb-8 overflow-hidden rounded-lg shadow-lg">
            <img 
              src={getImageUrl(post.featured_image)}
              alt={post.title}
              className="w-full h-full object-cover"
            />
          </div>
        )}
        <h1 className="text-4xl font-bold text-[#1B365D] mb-4">{post.title}</h1>
        <div className="flex items-center gap-4 mb-8">
          <p className="text-gray-500">
            {format(new Date(post.published_at || ''), 'd.M.yyyy')}
          </p>
          <div className="flex flex-wrap gap-2">
            {post.categories?.map((category) => (
              <Link 
                key={category.slug}
                to={`/blogi?category=${category.slug}`}
                className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 hover:bg-blue-200 transition-colors"
              >
                <Tag className="w-3 h-3 mr-1" />
                {category.name}
              </Link>
            ))}
          </div>
        </div>
        <div 
          className="prose prose-lg max-w-none prose-headings:text-[#1B365D] prose-h2:text-2xl prose-h2:font-semibold prose-h3:text-xl prose-p:text-gray-700 prose-p:leading-relaxed prose-a:text-[#1B365D] prose-a:no-underline hover:prose-a:underline prose-img:rounded-lg prose-img:shadow-lg"
          dangerouslySetInnerHTML={{ __html: post.content }} 
        />
      </article>
    </div>
  );
};

export default BlogPost;
