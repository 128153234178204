
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { MapPin, Building2, Home, Building, Ruler, Calendar, ActivitySquare, ThumbsUp, Heart, AlertTriangle } from "lucide-react";
import type { PlaceResult } from "@/hooks/useGoogleMaps";
import { AddressInput } from "@/components/AddressInput";
import { useTranslation } from "react-i18next";

interface PropertyDetails {
  address?: PlaceResult;
  size: string;
  condition: 'good' | 'average' | 'poor';
  propertyType: 'apartment' | 'house' | 'rowhouse';
  buildYear?: string;
}

interface PropertyDetailsFormProps {
  propertyDetails: PropertyDetails;
  onPropertyDetailsChange: (details: Partial<PropertyDetails>) => void;
  onNext: () => void;
}

export function PropertyDetailsForm({ 
  propertyDetails, 
  onPropertyDetailsChange, 
  onNext 
}: PropertyDetailsFormProps) {
  const { t } = useTranslation();
  
  return (
    <>
      <div className="space-y-2">
        <Label htmlFor="address" className="text-gray-700 flex items-center gap-2">
          <MapPin className="h-4 w-4" />
          {t('hintaArvio.form.propertyAddress.label')}
        </Label>
        <AddressInput
          onAddressSelect={(place) => onPropertyDetailsChange({ address: place })}
          className="w-full"
          placeholder={t('hintaArvio.form.propertyAddress.placeholder')}
        />
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div className="space-y-2">
          <Label htmlFor="size" className="text-gray-700 flex items-center gap-2">
            <Ruler className="h-4 w-4" />
            {t('hintaArvio.form.propertyDetails.size.label')}
          </Label>
          <Input
            id="size"
            type="number"
            placeholder={t('hintaArvio.form.propertyDetails.size.placeholder')}
            value={propertyDetails.size}
            onChange={(e) => onPropertyDetailsChange({ size: e.target.value })}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="buildYear" className="text-gray-700 flex items-center gap-2">
            <Calendar className="h-4 w-4" />
            {t('hintaArvio.form.propertyDetails.buildYear.label')}
          </Label>
          <Input
            id="buildYear"
            type="number"
            placeholder={t('hintaArvio.form.propertyDetails.buildYear.placeholder')}
            value={propertyDetails.buildYear}
            onChange={(e) => onPropertyDetailsChange({ buildYear: e.target.value })}
          />
        </div>
      </div>

      <div className="pt-4 space-y-6 border-t">
        <div className="space-y-2">
          <Label className="text-gray-700 flex items-center gap-2">
            <Building2 className="h-4 w-4" />
            {t('hintaArvio.form.propertyDetails.propertyType.label')}
          </Label>
          <div className="grid grid-cols-3 gap-4">
            {(['apartment', 'house', 'rowhouse'] as const).map((type) => (
              <Button
                key={type}
                type="button"
                variant={propertyDetails.propertyType === type ? "default" : "outline"}
                onClick={() => onPropertyDetailsChange({ propertyType: type })}
                className="w-full h-20 flex-col"
              >
                {type === 'apartment' && (
                  <>
                    <Building className="h-6 w-6 mb-1" />
                    <span>{t('hintaArvio.form.propertyDetails.propertyType.apartment')}</span>
                  </>
                )}
                {type === 'house' && (
                  <>
                    <Home className="h-6 w-6 mb-1" />
                    <span>{t('hintaArvio.form.propertyDetails.propertyType.house')}</span>
                  </>
                )}
                {type === 'rowhouse' && (
                  <>
                    <Building2 className="h-6 w-6 mb-1" />
                    <span>{t('hintaArvio.form.propertyDetails.propertyType.rowhouse')}</span>
                  </>
                )}
              </Button>
            ))}
          </div>
        </div>

        <div className="space-y-2">
          <Label className="text-gray-700 flex items-center gap-2">
            <ActivitySquare className="h-4 w-4" />
            {t('hintaArvio.form.propertyDetails.condition.label')}
          </Label>
          <div className="grid grid-cols-3 gap-4">
            {(['poor', 'average', 'good'] as const).map((condition) => (
              <Button
                key={condition}
                type="button"
                variant={propertyDetails.condition === condition ? "default" : "outline"}
                onClick={() => onPropertyDetailsChange({ condition })}
                className="w-full h-20 flex-col"
              >
                {condition === 'good' && (
                  <>
                    <ThumbsUp className="h-6 w-6 mb-1" />
                    <span>{t('hintaArvio.form.propertyDetails.condition.good')}</span>
                  </>
                )}
                {condition === 'average' && (
                  <>
                    <Heart className="h-6 w-6 mb-1" />
                    <span>{t('hintaArvio.form.propertyDetails.condition.average')}</span>
                  </>
                )}
                {condition === 'poor' && (
                  <>
                    <AlertTriangle className="h-6 w-6 mb-1" />
                    <span>{t('hintaArvio.form.propertyDetails.condition.poor')}</span>
                  </>
                )}
              </Button>
            ))}
          </div>
        </div>
      </div>

      <Button
        onClick={onNext}
        className="w-full mt-6 h-12 text-lg"
        disabled={!propertyDetails.address || !propertyDetails.size}
      >
        {t('hintaArvio.form.propertyDetails.nextButton')}
      </Button>
    </>
  );
}
