
import { useState } from "react";
import { useToast } from "@/hooks/use-toast";
import { useForm } from "@/contexts/FormContext";
import { apiService } from "@/services/apiService";
import { handleApiError } from "@/utils/errorHandler";
import { 
  validateForm, 
  createRegistrationData, 
  createEnquiryData,
  type FormState 
} from "@/utils/formUtils";
import { supabase } from "@/integrations/supabase/client";

export const useContactForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [showOtpVerification, setShowOtpVerification] = useState(false);
  const { toast } = useToast();
  const { updateFormData, setStep, formData } = useForm();
  const [formState, setFormState] = useState<FormState>({
    name: "",
    size: "",
    phone: "",
    email: "",
    additionalInfo: ""
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormState(prev => ({ ...prev, [name]: value }));
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone: string) => {
    const digitsOnly = phone.replace(/\D/g, '');
    return digitsOnly.length >= 8 && digitsOnly.length <= 12;
  };

  const sendAdminEmail = async () => {
    console.log("Attempting to send admin email with form state:", formState);
    try {
      // Format the phone number to include country code if it doesn't start with +
      let formattedPhone = formState.phone;
      if (!formattedPhone.startsWith('+')) {
        // Remove leading zero if present and add Finnish country code
        formattedPhone = '+358' + formattedPhone.replace(/^0/, '');
      }

      const emailData = {
        customerEmail: formState.email || "ei sähköpostia",
        adminEmail: "tuki@salaovi.fi",
        enquiryDetails: {
          name: formState.name || "Ei nimeä",
          address: formData.address?.address || "Ei osoitetta",
          size: formState.size || "Ei kokoa",
          phone: formattedPhone || "Ei puhelinnumeroa",
          email: formState.email || "ei sähköpostia",
          additionalInfo: formState.additionalInfo || undefined
        }
      };

      console.log("Sending admin email with data:", emailData);
      await apiService.sendEnquiryEmails(emailData);
      console.log("Admin email sent successfully");
      return true;
    } catch (error) {
      console.error("Failed to send admin email:", error);
      return false;
    }
  };

  const createEnquiryWithToken = async (token: string) => {
    if (!formData.address) return;

    try {
      const enquiryData = createEnquiryData(formState, formData.address);
      const result = await apiService.createEnquiry(token, enquiryData);
      
      if (!result.ok) {
        if (result.error?.type === "SORRY_NO_AGENT_FIND") {
          toast({
            title: "Ei välittäjiä alueella",
            description: "Hups! Emme ole ihan vielä täällä. Yritä muutaman päivän päästä uudelleen!",
            variant: "destructive",
          });
          return;
        }
        
        toast({
          title: "Virhe",
          description: result.error?.message || "Tapahtui virhe palvelimella",
          variant: "destructive",
        });
        return;
      }

      if (!result.data?.statusCode || result.data.statusCode !== 200) {
        const errorMessage = handleApiError(result.data);
        toast(errorMessage);
        return;
      }

      // After successful enquiry creation, save to notifications table
      try {
        await supabase
          .from('enquiries')
          .insert([
            {
              address: formData.address.address,
              city: formData.address.components.locality,
              postal_code: formData.address.components.postal_code,
              size: formState.size,
              first_name: formState.name.split(' ')[0],
              image_url: formData.address.streetViewPov ? 
                `https://maps.googleapis.com/maps/api/streetview?location=${formData.address.latitude},${formData.address.longitude}&size=1200x456&heading=${formData.address.streetViewPov.heading}&pitch=${formData.address.streetViewPov.pitch}&key=AIzaSyAkfhQ_hKSnM3dBcPPWA4RT_YCtfusjUMc` : 
                null
            }
          ]);
      } catch (error) {
        console.error('Error saving notification data:', error);
        // Don't throw error as this is not critical for the main functionality
      }

      setShowOtpVerification(true);
    } catch (error) {
      console.error('Create enquiry error:', error);
      const errorMessage = handleApiError(error);
      toast(errorMessage);
      throw error;
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log("Form submission started with state:", formState);
    setIsLoading(true);

    // Always send admin email first, regardless of validation
    await sendAdminEmail();

    try {
      // Validate the form after sending admin email
      const validationErrors = [];
      if (!validateEmail(formState.email)) validationErrors.push("Tarkista sähköpostiosoitteen muoto");
      if (!validatePhone(formState.phone)) validationErrors.push("Tarkista puhelinnumeron muoto");
      if (!validateForm(formState)) validationErrors.push("Täytä kaikki pakolliset kentät");

      if (validationErrors.length > 0) {
        toast({
          title: "Huomautus",
          description: validationErrors.join(". "),
          variant: "destructive",
        });
        setIsLoading(false);
        return;
      }

      const registrationData = createRegistrationData(formState, formData);
      console.log("Attempting registration with data:", registrationData);
      
      const registerResponse = await apiService.register(registrationData);
      
      if (registerResponse.ok && formData.address) {
        const responseData = await registerResponse.json();
        console.log('Registration response:', responseData);
        
        if (!responseData?.statusCode || responseData.statusCode !== 200) {
          throw new Error("Registration failed");
        }

        if (!responseData?.data?.accessToken) {
          throw new Error("No authentication token received");
        }

        const token = responseData.data.accessToken;
        
        updateFormData({ 
          accessToken: token,
          size: formState.size,
          email: formState.email,
          phone: formState.phone,
          countryCode: "+358",
          contactNumber: formState.phone.replace(/^0/, '')
        });

        await createEnquiryWithToken(token);
      } else {
        const errorData = await registerResponse.json();
        if (errorData.type === "EMAIL_ALREADY_EXIST") {
          setShowLogin(true);
        } else {
          const errorMessage = handleApiError(errorData);
          toast(errorMessage);
        }
      }
    } catch (error) {
      console.error('Form submission error:', error);
      if (error instanceof Error && error.message === "EMAIL_ALREADY_EXISTS") {
        setShowLogin(true);
      } else {
        const errorMessage = handleApiError(error);
        toast(errorMessage);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleLoginSuccess = async (token: string) => {
    try {
      setIsLoading(true);
      await createEnquiryWithToken(token);
    } catch (error) {
      console.error('Error creating enquiry after login:', error);
      const errorMessage = handleApiError(error);
      toast(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOtpVerificationSuccess = () => {
    setStep(4);
  };

  return {
    formState,
    isLoading,
    showLogin,
    showTerms,
    showOtpVerification,
    handleChange,
    handleSubmit,
    handleLoginSuccess,
    handleOtpVerificationSuccess,
    setShowLogin,
    setShowTerms,
  };
};
