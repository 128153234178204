
import { Link, useNavigate } from "react-router-dom";
import { Facebook, Linkedin, Instagram, ChevronRight } from "lucide-react";
import { useTranslation } from "react-i18next";

// Custom link component that scrolls to top on click
const ScrollToTopLink = ({ to, children, className }: { to: string; children: React.ReactNode; className?: string }) => {
  const navigate = useNavigate();

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(to);
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 100);
  };

  return (
    <a href={to} onClick={handleClick} className={className}>
      {children}
    </a>
  );
};

// Type definitions for our footer sections
interface ServiceLink {
  text: string;
  path: string;
}

export const Footer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const scrollToTopHome = () => {
    navigate('/');
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 100);
  };

  // Get translated arrays with proper type casting and fallbacks
  const cities = (t('footer.sections.realEstate.cities', { returnObjects: true }) || []) as string[];
  const propertyTradeLinks = (t('footer.sections.propertyTrade.links', { returnObjects: true }) || []) as string[];
  const serviceLinks = (t('footer.sections.services.links', { returnObjects: true }) || []) as ServiceLink[];
  const regions = (t('footer.sections.regions.areas', { returnObjects: true }) || []) as string[];

  return (
    <footer>
      {/* CTA Section */}
      <div className="bg-gradient-to-br from-[#1B365D] to-[#152C4A] py-20">
        <div className="container max-w-4xl mx-auto text-center">
          <div className="bg-white/5 backdrop-blur-sm rounded-2xl p-10 shadow-2xl">
            <h2 className="text-3xl md:text-4xl font-bold mb-6 text-white">
              {t('footer.cta.title')}
            </h2>
            <p className="text-white/90 mb-8 text-lg max-w-2xl mx-auto">
              {t('footer.cta.description')}
            </p>
            <button 
              onClick={scrollToTopHome}
              className="bg-white text-[#1B365D] px-8 py-4 rounded-xl font-semibold text-lg hover:bg-white/90 transition-all duration-300 transform hover:-translate-y-1 hover:shadow-lg inline-flex items-center gap-2 group"
            >
              {t('footer.cta.button')}
              <ChevronRight className="w-5 h-5 transform group-hover:translate-x-1 transition-transform" />
            </button>
          </div>
        </div>
      </div>

      {/* Main Footer Content */}
      <div className="bg-[#1B365D]">
        <div className="container py-16">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12 mb-16">
            {/* Logo Column */}
            <div className="flex flex-col items-center md:items-start space-y-6">
              <img 
                src="/lovable-uploads/a3721f0a-da5e-4a03-a788-ab853b86804f.png" 
                alt="Salaovi Logo" 
                className="h-12"
              />
              <div className="flex space-x-4">
                <a 
                  href="https://facebook.com" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="bg-white/5 hover:bg-white/10 p-3 rounded-xl transition-all duration-300 hover:-translate-y-1"
                >
                  <Facebook className="h-5 w-5 text-white" />
                </a>
                <a 
                  href="https://linkedin.com" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="bg-white/5 hover:bg-white/10 p-3 rounded-xl transition-all duration-300 hover:-translate-y-1"
                >
                  <Linkedin className="h-5 w-5 text-white" />
                </a>
                <a 
                  href="https://instagram.com" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="bg-white/5 hover:bg-white/10 p-3 rounded-xl transition-all duration-300 hover:-translate-y-1"
                >
                  <Instagram className="h-5 w-5 text-white" />
                </a>
              </div>
            </div>

            {/* Company Info Column */}
            <div className="text-center md:text-left">
              <h3 className="text-lg font-semibold mb-6 text-white">
                {t('footer.company.title')}
              </h3>
              <div className="space-y-4 text-white/80">
                <p>{t('footer.company.info.name')}</p>
                <p>{t('footer.company.info.businessId')}</p>
                <div className="mt-6 p-4 bg-white/5 rounded-xl">
                  <h4 className="font-semibold mb-2 text-white">
                    {t('footer.company.info.customerService.title')}
                  </h4>
                  <p className="hover:text-white transition-colors">
                    {t('footer.company.info.customerService.website')}
                  </p>
                  <p className="hover:text-white transition-colors">
                    {t('footer.company.info.customerService.phone')}
                  </p>
                </div>
              </div>
            </div>

            {/* Links Column */}
            <div className="text-center md:text-left">
              <h3 className="text-lg font-semibold mb-6 text-white">
                {t('footer.cooperation.title')}
              </h3>
              <div className="text-white/80">
                <p className="mb-4">{t('footer.cooperation.description')}</p>
                <ScrollToTopLink 
                  to="/valittajille" 
                  className="inline-flex items-center gap-2 bg-white/5 px-6 py-3 rounded-xl hover:bg-white/10 transition-all duration-300 group"
                >
                  {t('footer.cooperation.link')}
                  <ChevronRight className="w-4 h-4 transform group-hover:translate-x-1 transition-transform" />
                </ScrollToTopLink>
              </div>
            </div>
          </div>

          {/* SEO Links Section */}
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8 py-12 border-t border-b border-white/10">
            <div className="space-y-4">
              <h4 className="font-semibold text-white mb-4">
                {t('footer.sections.realEstate.title')}
              </h4>
              <ul className="space-y-3">
                {cities.map((city) => (
                  <li key={city}>
                    <ScrollToTopLink 
                      to="/" 
                      className="text-white/70 hover:text-white transition-colors inline-flex items-center gap-1 group"
                    >
                      <span>{`${t('footer.sections.realEstate.prefix')} ${city}`}</span>
                      <ChevronRight className="w-4 h-4 opacity-0 group-hover:opacity-100 transform group-hover:translate-x-1 transition-all" />
                    </ScrollToTopLink>
                  </li>
                ))}
              </ul>
            </div>
            
            <div className="space-y-4">
              <h4 className="font-semibold text-white mb-4">
                {t('footer.sections.propertyTrade.title')}
              </h4>
              <ul className="space-y-3">
                {propertyTradeLinks.map((item) => (
                  <li key={item}>
                    <ScrollToTopLink 
                      to="/" 
                      className="text-white/70 hover:text-white transition-colors inline-flex items-center gap-1 group"
                    >
                      <span>{item}</span>
                      <ChevronRight className="w-4 h-4 opacity-0 group-hover:opacity-100 transform group-hover:translate-x-1 transition-all" />
                    </ScrollToTopLink>
                  </li>
                ))}
              </ul>
            </div>

            <div className="space-y-4">
              <h4 className="font-semibold text-white mb-4">
                {t('footer.sections.services.title')}
              </h4>
              <ul className="space-y-3">
                {serviceLinks.map((item) => (
                  <li key={item.text}>
                    <ScrollToTopLink 
                      to={item.path}
                      className="text-white/70 hover:text-white transition-colors inline-flex items-center gap-1 group"
                    >
                      <span>{item.text}</span>
                      <ChevronRight className="w-4 h-4 opacity-0 group-hover:opacity-100 transform group-hover:translate-x-1 transition-all" />
                    </ScrollToTopLink>
                  </li>
                ))}
              </ul>
            </div>

            <div className="space-y-4">
              <h4 className="font-semibold text-white mb-4">
                {t('footer.sections.regions.title')}
              </h4>
              <ul className="space-y-3">
                {regions.map((region) => (
                  <li key={region}>
                    <ScrollToTopLink 
                      to="/" 
                      className="text-white/70 hover:text-white transition-colors inline-flex items-center gap-1 group"
                    >
                      <span>{region}</span>
                      <ChevronRight className="w-4 h-4 opacity-0 group-hover:opacity-100 transform group-hover:translate-x-1 transition-all" />
                    </ScrollToTopLink>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Bottom Bar */}
      <div className="bg-[#152C4A] py-4">
        <div className="container flex flex-col md:flex-row justify-between items-center text-sm text-white/60">
          <div className="mb-4 md:mb-0">
            {t('footer.bottomBar.copyright')}
          </div>
          <div className="flex gap-6">
            <ScrollToTopLink to="/tietosuojakaytanto" className="hover:text-white transition-colors">
              {t('footer.bottomBar.links.privacy')}
            </ScrollToTopLink>
            <ScrollToTopLink to="/palvelun-kayttoehdot" className="hover:text-white transition-colors">
              {t('footer.bottomBar.links.terms')}
            </ScrollToTopLink>
          </div>
        </div>
      </div>
    </footer>
  );
};
