
import { createContext, useContext, useState, ReactNode } from "react";
import type { PlaceResult } from "@/hooks/useGoogleMaps";

interface FormData {
  address?: PlaceResult;
  size?: string;
  condition?: 'good' | 'average' | 'poor';
  propertyType?: 'apartment' | 'house' | 'rowhouse';
  buildYear?: string;
  email?: string;
  phone?: string;
  salesTimeline?: 'asap' | '1month' | '2months' | '6months' | 'not_selling';
  countryCode?: string;
  contactNumber?: string;
  additionalInfo?: string;
  deviceType?: string;
  deviceToken?: string;
  type?: string;
  authorization?: string;
  accessToken?: string;
  otpVerified?: boolean;
  verificationId?: string;
  streetViewPov?: {
    heading: number;
    pitch: number;
  };
}

interface FormContextType {
  step: number;
  formData: FormData;
  setStep: (step: number) => void;
  updateFormData: (data: Partial<FormData>) => void;
}

const FormContext = createContext<FormContextType | undefined>(undefined);

interface FormProviderProps {
  children: ReactNode;
  initialData?: FormData;
}

export function FormProvider({ children, initialData = {} }: FormProviderProps) {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState<FormData>({
    deviceType: "ANDROID",
    type: "CUSTOMER",
    deviceToken: "1",
    countryCode: "+358",
    otpVerified: false,
    ...initialData
  });

  const updateFormData = (data: Partial<FormData>) => {
    console.log('Updating form data:', data);
    setFormData(prev => ({ ...prev, ...data }));
  };

  return (
    <FormContext.Provider value={{ step, setStep, formData, updateFormData }}>
      {children}
    </FormContext.Provider>
  );
}

export const useForm = () => {
  const context = useContext(FormContext);
  if (!context) throw new Error("useForm must be used within FormProvider");
  return context;
};
