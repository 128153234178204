import { supabase } from "@/integrations/supabase/client";
import { imageService } from "./imageService";
import { categoryService } from "./categoryService";
import type { ImportResult, BlogPost } from "@/types/blog";

export const wordpressImportService = {
  async processWordPressContent(content: string): Promise<{ content: string; featuredImage: string | null }> {
    const imgRegex = /<img[^>]+src="([^">]+)"/g;
    const imageUrls = Array.from(content.matchAll(imgRegex)).map(match => match[1]);
    let featuredImage: string | null = null;

    // Process each image URL
    for (const imageUrl of imageUrls) {
      const newImageUrl = await imageService.downloadAndUploadImage(imageUrl);
      if (newImageUrl) {
        if (!featuredImage) {
          featuredImage = newImageUrl;
        }
        content = content.replace(imageUrl, newImageUrl);
      }
    }

    // Also check for WordPress featured image
    const featuredImageRegex = /<wp:attachment_url>([^<]+)<\/wp:attachment_url>/;
    const wpFeaturedImage = content.match(featuredImageRegex)?.[1];
    if (wpFeaturedImage && !featuredImage) {
      featuredImage = await imageService.downloadAndUploadImage(wpFeaturedImage);
    }

    content = content
      .replace(/<\/?wp:[^>]+>/g, '')
      .replace(/[""]/g, '"')
      .replace(/<!--[\s\S]*?-->/g, '')
      .trim();

    if (!content.includes('<p>')) {
      content = content
        .split(/\n\n+/)
        .map(para => `<p>${para.trim()}</p>`)
        .join('\n');
    }

    return { content, featuredImage };
  },

  async importWordPressFile(file: File): Promise<ImportResult> {
    console.log('Processing WordPress file:', file.name);
    
    try {
      const text = await file.text();
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(text, 'text/xml');
      const items = xmlDoc.getElementsByTagName('item');
      const posts: Array<Omit<BlogPost, 'id' | 'created_at' | 'updated_at'> & { categories?: { name: string; slug: string; }[] }> = [];
      const seenSlugs = new Set<string>();

      for (const item of items) {
        // Check all categories and tags for "valitysliikkeet"
        const allCategoriesAndTags = Array.from(item.getElementsByTagName('category'));
        const hasValitysliikkeet = allCategoriesAndTags.some(cat => 
          cat.textContent?.trim().toLowerCase() === 'valitysliikkeet' ||
          cat.getAttribute('nicename')?.toLowerCase() === 'valitysliikkeet'
        );

        // Skip this post if it has valitysliikkeet in any category or tag
        if (hasValitysliikkeet) {
          console.log('Skipping post with valitysliikkeet tag/category');
          continue;
        }

        // Process only actual categories (not tags) for the post
        const categories = Array.from(item.getElementsByTagName('category'))
          .filter(cat => cat.getAttribute('domain') === 'category')
          .map(cat => ({
            name: cat.textContent?.trim() || '',
            slug: cat.getAttribute('nicename') || cat.textContent?.toLowerCase().trim().replace(/[^a-z0-9]+/g, '-') || ''
          }));

        const title = item.getElementsByTagName('title')[0]?.textContent || '';
        let initialContent = item.getElementsByTagName('content:encoded')[0]?.textContent || '';
        const excerpt = item.getElementsByTagName('excerpt:encoded')[0]?.textContent || '';
        const pubDate = item.getElementsByTagName('pubDate')[0]?.textContent || '';
        const postId = item.getElementsByTagName('wp:post_id')[0]?.textContent;

        // Generate slug from title
        const cleanTitle = title
          .toLowerCase()
          .trim()
          .replace(/[^a-z0-9\s-åäöÅÄÖ]/g, '') // Allow Finnish characters
          .replace(/å/g, 'a')
          .replace(/ä/g, 'a')
          .replace(/ö/g, 'o')
          .replace(/\s+/g, '-')
          .replace(/-+/g, '-'); // Replace multiple hyphens with single hyphen

        let baseSlug = cleanTitle;
        
        // Handle empty or invalid titles
        if (!baseSlug || baseSlug.length < 3) {
          baseSlug = 'blog-post';
        }

        // Handle duplicate slugs by adding a suffix
        let slug = baseSlug;
        let counter = 1;
        while (seenSlugs.has(slug)) {
          slug = `${baseSlug}-${counter}`;
          counter++;
        }
        seenSlugs.add(slug);

        let featuredImage: string | null = null;
        let processedContent = initialContent;
        
        // First try to get the WordPress featured image
        const featuredImageNode = item.getElementsByTagName('wp:attachment_url')[0];
        const wpFeaturedImageUrl = featuredImageNode?.textContent || null;

        if (wpFeaturedImageUrl) {
          featuredImage = await imageService.downloadAndUploadImage(wpFeaturedImageUrl);
        }

        // If no featured image yet, process content and get first image
        if (!featuredImage) {
          const processedResult = await wordpressImportService.processWordPressContent(initialContent);
          featuredImage = processedResult.featuredImage;
          processedContent = processedResult.content;
        }

        const cleanExcerpt = excerpt
          .replace(/<\/?wp:[^>]+>/g, '')
          .replace(/[""]/g, '"')
          .replace(/<!--[\s\S]*?-->/g, '')
          .replace(/<\/?p>/g, '')
          .replace(/<br\s*\/?>/g, ' ')
          .trim();

        if (title && processedContent) {
          posts.push({
            title,
            content: processedContent,
            excerpt: cleanExcerpt || processedContent.substring(0, 150) + '...',
            published_at: new Date(pubDate).toISOString(),
            slug,
            featured_image: featuredImage,
            original_wordpress_id: postId ? parseInt(postId, 10) : null,
            categories
          });
        }
      }

      if (posts.length === 0) {
        throw new Error('No valid posts found in the XML file');
      }

      // Extract unique categories from all posts
      const allCategories = Array.from(new Set(
        posts.flatMap(post => post.categories || [])
          .map(cat => JSON.stringify(cat)) // Convert to string for proper Set deduplication
      )).map(cat => JSON.parse(cat)); // Convert back to object

      console.log('Found categories:', allCategories);

      // Insert all categories first
      await categoryService.insertCategories(allCategories);

      // Insert posts one at a time to avoid conflicts
      for (const post of posts) {
        // Remove categories from post data before insertion
        const { categories: postCategories, ...postData } = post;
        
        const { data: insertedPost, error: insertError } = await supabase
          .from('blog_posts')
          .upsert(postData, { onConflict: 'slug' })
          .select()
          .single();

        if (insertError) {
          console.error('Error inserting post:', insertError);
          throw insertError;
        }

        // Link categories to post
        if (insertedPost && postCategories) {
          await categoryService.linkPostToCategories(insertedPost.id, postCategories);
        }
      }

      return { 
        success: true, 
        message: `Successfully imported ${posts.length} blog posts` 
      };

    } catch (error) {
      console.error('Import error:', error);
      throw error;
    }
  }
};
