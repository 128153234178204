
import { supabase } from "@/integrations/supabase/client";
import type { Category } from "@/types/blog";

export const categoryService = {
  async insertCategories(categories: { name: string; slug: string; }[]) {
    for (const category of categories) {
      const { error } = await supabase
        .from('categories')
        .upsert({ name: category.name, slug: category.slug }, { 
          onConflict: 'slug'
        });

      if (error) {
        console.error('Category insert error:', error);
        throw error;
      }
    }
  },

  async linkPostToCategories(postId: string, categories: { name: string; slug: string; }[]) {
    for (const category of categories) {
      const { data: categoryData } = await supabase
        .from('categories')
        .select('id')
        .eq('slug', category.slug)
        .single();

      if (categoryData) {
        const { error: linkError } = await supabase
          .from('blog_posts_categories')
          .upsert({
            post_id: postId,
            category_id: categoryData.id
          });

        if (linkError) {
          console.error('Category linking error:', linkError);
        }
      }
    }
  }
};
