
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

interface FAQItem {
  question: string;
  answer: string;
}

const defaultFaqs: FAQItem[] = [
  {
    question: "Is the service completely free?",
    answer: "Yes, our service is completely free for property sellers. You'll get agent offers for comparison without any commitments."
  },
  {
    question: "How does the agent comparison work in practice?",
    answer: "You fill out an easy-to-use form with your property details. We forward the information to the best agents in your area, who will send you their competitive offers. You'll get all offers in one place for comparison and can choose the best one."
  },
  {
    question: "How quickly will I receive agent offers?",
    answer: "You'll receive the first offers within 15 minutes of submitting your application. We guarantee that you'll receive all offers within 24 hours."
  },
  {
    question: "Why should I compare agents?",
    answer: "By comparing agents, you'll get better commission rates and ensure agent motivation. You'll also see agents' estimates of your property's sale price and time-to-sell. This helps you make an informed decision."
  }
];

// Type guard to check if an object is a FAQItem
const isFAQItem = (item: any): item is FAQItem => {
  return typeof item === 'object' 
    && item !== null
    && typeof item.question === 'string' 
    && typeof item.answer === 'string';
};

// Type guard to check if an array is FAQItem[]
const isFAQArray = (arr: any): arr is FAQItem[] => {
  return Array.isArray(arr) && arr.every(isFAQItem);
};

export const FAQ = () => {
  const { t, ready } = useTranslation();
  const [faqs, setFaqs] = useState<FAQItem[]>(defaultFaqs);

  useEffect(() => {
    if (ready) {
      try {
        const translatedFaqs = t('faq.questions', { returnObjects: true });
        if (isFAQArray(translatedFaqs)) {
          setFaqs(translatedFaqs);
        }
      } catch (error) {
        console.error('Error loading FAQ translations:', error);
      }
    }
  }, [t, ready]);

  return (
    <section className="py-20 px-4 bg-white">
      <div className="max-w-3xl mx-auto">
        <div className="text-center mb-16 animate-fade-in">
          <h2 className="text-3xl md:text-4xl font-bold mb-4 text-[#1B365D]">
            {t('faq.title', 'Frequently Asked Questions')}
          </h2>
        </div>
        <Accordion type="single" collapsible className="space-y-4">
          {faqs.map((faq, index) => (
            <AccordionItem 
              key={index} 
              value={`item-${index}`} 
              className="animate-fade-in" 
              style={{ animationDelay: `${index * 200}ms` }}
            >
              <AccordionTrigger className="text-lg font-semibold text-[#1B365D]">
                {faq.question}
              </AccordionTrigger>
              <AccordionContent className="text-gray-700">
                {faq.answer}
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </section>
  );
};
