
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { useTranslation } from "react-i18next";

export const Benefits = () => {
  const { t } = useTranslation();

  return (
    <section className="py-16 px-4 bg-gray-50">
      <div className="max-w-7xl mx-auto grid md:grid-cols-2 gap-8">
        {/* Left Column */}
        <div className="space-y-6">
          <h2 className="text-3xl font-bold text-[#1B365D]">
            {t('benefits.title')}
          </h2>
          <p className="text-gray-600">
            {t('benefits.description')}
          </p>
          <Button 
            className="w-full md:w-auto bg-[#1B365D] hover:bg-[#152C4A] text-white px-8 py-6 rounded-lg text-lg"
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          >
            {t('benefits.startComparison')}
          </Button>
        </div>

        {/* Right Column */}
        <Card className="p-8 space-y-8 bg-white shadow-lg">
          <div className="space-y-4">
            <h3 className="text-2xl font-bold text-[#1B365D]">
              {t('benefits.card.bestOffers.title')}
            </h3>
            <p className="text-gray-600">
              {t('benefits.card.bestOffers.description')}
            </p>
          </div>

          <div className="space-y-4">
            <h3 className="text-2xl font-bold text-[#1B365D]">
              {t('benefits.card.partners.title')}
            </h3>
            <p className="text-gray-600">
              {t('benefits.card.partners.description')}
            </p>
          </div>

          <div className="space-y-4">
            <h3 className="text-2xl font-bold text-[#1B365D]">
              {t('benefits.card.requirements.title')}
            </h3>
            <p className="text-gray-600">
              {t('benefits.card.requirements.description')}
            </p>
          </div>
        </Card>
      </div>
    </section>
  );
};
