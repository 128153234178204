
import { useQuery } from "@tanstack/react-query";
import { blogService } from "@/services/blogService";
import { Link } from "react-router-dom";
import { ArrowRight } from "lucide-react";
import { useTranslation } from "react-i18next";

export const BlogRoll = () => {
  const { t } = useTranslation();
  const { data: blogPosts, isLoading } = useQuery({
    queryKey: ['blogPosts'],
    queryFn: blogService.getBlogPosts,
  });

  if (isLoading || !blogPosts) {
    return null;
  }

  // Shuffle array and get 6 random posts
  const shuffledPosts = [...blogPosts].sort(() => Math.random() - 0.5).slice(0, 6);

  const getImageUrl = (url: string | null) => {
    if (!url) return null;
    if (url.startsWith('http')) return url;
    return `https://yfhzflexahlxpaogypbx.supabase.co/storage/v1/object/public/blog_images/${url}`;
  };

  // Function to strip HTML tags from text
  const stripHtml = (html: string) => {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  return (
    <section className="py-20 px-4 bg-white">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold mb-4 text-[#1B365D]">
            {t('blog.title')}
          </h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            {t('blog.description')}
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
          {shuffledPosts.map((post) => (
            <Link 
              key={post.id}
              to={`/blogi/${post.slug}`}
              className="group cursor-pointer"
            >
              <div className="bg-white rounded-lg shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl h-full">
                {post.featured_image && (
                  <div className="aspect-video overflow-hidden">
                    <img 
                      src={getImageUrl(post.featured_image)}
                      alt={post.title}
                      className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                    />
                  </div>
                )}
                <div className="p-6">
                  <h3 className="text-xl font-semibold text-[#1B365D] mb-3 line-clamp-2">
                    {post.title}
                  </h3>
                  <p className="text-gray-700 mb-4 line-clamp-2">
                    {post.excerpt ? stripHtml(post.excerpt) : ''}
                  </p>
                  <span className="text-[#1B365D] font-medium group-hover:underline inline-flex items-center">
                    Lue lisää
                    <ArrowRight className="ml-2 w-4 h-4 transition-transform group-hover:translate-x-1" />
                  </span>
                </div>
              </div>
            </Link>
          ))}
        </div>

        <div className="text-center">
          <Link 
            to="/blogi"
            className="inline-flex items-center text-[#1B365D] font-medium hover:underline text-lg"
          >
            Katso kaikki artikkelit
            <ArrowRight className="ml-2 w-5 h-5 transition-transform group-hover:translate-x-1" />
          </Link>
        </div>
      </div>
    </section>
  );
};
