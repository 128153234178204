
import { Card } from "@/components/ui/card";
import { CheckCircle2, Star, Euro, ExternalLink } from "lucide-react";
import { useTranslation } from "react-i18next";

const pricingOptions = [
  {
    icon: CheckCircle2,
    translationKey: "makingOffers"
  },
  {
    icon: Star,
    translationKey: "beingSelected"
  },
  {
    icon: Euro,
    translationKey: "dealCompletion"
  }
];

export const PricingSection = () => {
  const { t } = useTranslation();

  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-6">{t('forAgents.pricing.title')}</h2>
        <p className="text-gray-600 text-center mb-12 max-w-2xl mx-auto">
          {t('forAgents.pricing.subtitle')}
        </p>
        <div className="max-w-3xl mx-auto">
          <Card className="overflow-hidden bg-gradient-to-br from-white to-gray-50">
            <div className="p-8">
              <div className="grid gap-6">
                {pricingOptions.map((option, index) => {
                  const Icon = option.icon;
                  return (
                    <div key={index} className="flex items-center gap-4 p-4 bg-[#1B365D]/5 rounded-lg">
                      <div className="w-12 h-12 bg-[#1B365D] rounded-full flex items-center justify-center shrink-0">
                        <Icon className="h-6 w-6 text-white" />
                      </div>
                      <div>
                        <h3 className="text-xl font-semibold mb-1">
                          {t(`forAgents.pricing.options.${option.translationKey}.title`)}
                        </h3>
                        <p className="text-[#1B365D] text-2xl font-bold">
                          {t(`forAgents.pricing.options.${option.translationKey}.price`)}
                        </p>
                        <p className="text-gray-600 text-sm">
                          {t(`forAgents.pricing.options.${option.translationKey}.description`)}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="mt-8 pt-6 border-t border-gray-200">
                <p className="text-gray-500 text-sm mb-6">{t('forAgents.pricing.vatNotice')}</p>
                <div className="flex justify-center">
                  <a 
                    href="https://partners.salaovi.fi/register"
                    className="inline-flex items-center gap-2 bg-[#1B365D] text-white px-8 py-4 rounded-lg font-medium hover:bg-[#152C4A] transition-colors text-lg group"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('forAgents.pricing.registerNow')}
                    <ExternalLink className="h-5 w-5 group-hover:translate-x-1 transition-transform" />
                  </a>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </section>
  );
};
