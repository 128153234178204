
import { useEffect, useRef } from "react";
import { useForm } from "@/contexts/FormContext";

interface StreetViewProps {
  className?: string;
}

// Define the google.maps namespace types we need
declare global {
  namespace google.maps {
    class StreetViewPanorama {
      constructor(
        container: HTMLElement,
        options?: StreetViewPanoramaOptions
      );
      setPosition(position: LatLngLiteral): void;
      getPosition(): LatLng;
      getPov(): StreetViewPov;
      setPov(pov: StreetViewPov): void;
      addListener(eventName: string, handler: () => void): MapsEventListener;
    }

    class StreetViewService {
      getPanorama(request: StreetViewLocationRequest): Promise<StreetViewResponse>;
    }

    interface StreetViewPanoramaOptions {
      position: LatLngLiteral;
      pov: StreetViewPov;
      addressControl?: boolean;
      showRoadLabels?: boolean;
      motionTracking?: boolean;
      motionTrackingControl?: boolean;
      fullscreenControl?: boolean;
      panControl?: boolean;
      zoomControl?: boolean;
      clickToGo?: boolean;
      scrollwheel?: boolean;
      disableDefaultUI?: boolean;
    }

    interface StreetViewPov {
      heading: number;
      pitch: number;
    }

    interface LatLngLiteral {
      lat: number;
      lng: number;
    }

    interface LatLng {
      lat(): number;
      lng(): number;
    }

    interface StreetViewLocationRequest {
      location: LatLngLiteral;
      radius?: number;
    }

    interface StreetViewResponse {
      data?: any;
    }

    interface MapsEventListener {
      remove(): void;
    }

    namespace event {
      function clearInstanceListeners(instance: any): void;
    }
  }
}

export const StreetView = ({ className }: StreetViewProps) => {
  const streetViewRef = useRef<HTMLDivElement>(null);
  const panoramaRef = useRef<google.maps.StreetViewPanorama | null>(null);
  const { formData, updateFormData } = useForm();

  useEffect(() => {
    if (!streetViewRef.current || !formData.address) return;

    try {
      const { latitude, longitude } = formData.address;
      const position = { lat: latitude, lng: longitude };

      // Check if we already have a panorama instance
      if (panoramaRef.current) {
        panoramaRef.current.setPosition(position);
        return;
      }

      const streetViewService = new google.maps.StreetViewService();
      
      // Check if Street View is available at this location
      streetViewService.getPanorama({ location: position, radius: 50 })
        .then(() => {
          if (!streetViewRef.current) return;

          const panorama = new google.maps.StreetViewPanorama(streetViewRef.current, {
            position,
            pov: formData.streetViewPov || { heading: 34, pitch: 10 },
            addressControl: false,
            showRoadLabels: false,
            motionTracking: false,
            motionTrackingControl: false,
            fullscreenControl: false,
            panControl: true,
            zoomControl: true,
            clickToGo: true,
            scrollwheel: true,
            disableDefaultUI: true
          });

          // Update POV only when user changes it
          let timeoutId: number;
          panorama.addListener('pov_changed', () => {
            // Clear any existing timeout
            if (timeoutId) {
              window.clearTimeout(timeoutId);
            }
            
            // Set a new timeout
            timeoutId = window.setTimeout(() => {
              const pov = panorama.getPov();
              console.log('Street view POV changed:', pov); // Added log to verify updates
              if (JSON.stringify(pov) !== JSON.stringify(formData.streetViewPov)) {
                console.log('Updating form data with new POV:', pov); // Added log to verify updates
                updateFormData({ streetViewPov: pov });
              }
            }, 500); // Debounce for 500ms
          });

          panoramaRef.current = panorama;
        })
        .catch((error) => {
          console.error("Street View not available at this location:", error);
        });

      return () => {
        if (panoramaRef.current) {
          google.maps.event.clearInstanceListeners(panoramaRef.current);
          panoramaRef.current = null;
        }
      };
    } catch (error) {
      console.error("Error initializing Street View:", error);
    }
  }, [formData.address]);

  return (
    <div 
      ref={streetViewRef} 
      className={`w-full h-48 sm:h-64 md:h-72 rounded-lg overflow-hidden ${className || ''}`}
    />
  );
};
