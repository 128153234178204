
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";

interface TermsDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onAccept: () => void;
}

export function TermsDialog({ open, onOpenChange, onAccept }: TermsDialogProps) {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-2xl">
        <DialogHeader>
          <DialogTitle className="text-2xl font-bold text-[#1B365D]">
            Palvelun käyttöehdot
          </DialogTitle>
          <DialogDescription className="text-gray-600">
            Lue ja hyväksy palvelun käyttöehdot jatkaaksesi
          </DialogDescription>
        </DialogHeader>

        <ScrollArea className="h-[400px] pr-4">
          <div className="space-y-6">
            <section>
              <h3 className="text-xl font-semibold text-[#1B365D] mb-3">
                Välittäjävertailun ehdot
              </h3>
              <p className="text-gray-700 leading-relaxed">
                Salaovi palvelu vertailee sinulle alueesi kiinteistönvälittäjät jotka täyttävät kriteerimme, 
                täysin ilmaiseksi. Valikoidut välittäjät ottavat sinuun yhteyttä ja saat kaikilta parhaan 
                kilpailukykyisen tarjouksen. Kilpailutuksen aloittamisen ehtona on ainoastaan että olet 
                aloittamassa asuntosi myymisen lähiaikoina. Sinulle jää tämän jälkeen enää tehtäväksi 
                valita näistä juuri sinulle sopivin. Saat tarjoukset sähköpostiisi ja sinun tulee 
                hyväksyä tai hylätä tarjoukset tätä kautta.
              </p>
            </section>

            <section>
              <h3 className="text-xl font-semibold text-[#1B365D] mb-3">
                Henkilötietojen käsittely
              </h3>
              <p className="text-gray-700 leading-relaxed">
                Palvelussa kerättäviä henkilötietoja käsitellessä noudatetaan henkilötietolakia ja 
                käsittelemme tietoja sen mukaisesti. Tiedot ovat osa yrityksen asiakasrekisteriä. 
                Tietoja voidaan käyttää palveluiden markkinointiin.
              </p>
            </section>
          </div>
        </ScrollArea>

        <DialogFooter className="mt-6">
          <div className="flex flex-col sm:flex-row gap-3 w-full sm:justify-between items-center">
            <button
              onClick={() => onOpenChange(false)}
              className="text-gray-500 hover:text-gray-700 text-sm font-medium"
            >
              Peruuta
            </button>
            <Button 
              onClick={onAccept}
              className="bg-[#1B365D] hover:bg-[#152C4A] min-w-[200px]"
            >
              Hyväksyn ehdot
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
