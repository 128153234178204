
import { createClient } from '@supabase/supabase-js';
import type { Database } from './types';

const SUPABASE_URL = "https://yfhzflexahlxpaogypbx.supabase.co";
const SUPABASE_PUBLISHABLE_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InlmaHpmbGV4YWhseHBhb2d5cGJ4Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3Mzk4MDc4NjMsImV4cCI6MjA1NTM4Mzg2M30.L06tM0eG0IvBSGuzCmgwE3w8LfbfjcFZ3P3zji9CXk0";

// Import the supabase client like this:
// import { supabase } from "@/integrations/supabase/client";

export const supabase = createClient<Database>(SUPABASE_URL, SUPABASE_PUBLISHABLE_KEY, {
  auth: {
    autoRefreshToken: true,
    persistSession: true
  }
});
