
import { Globe, Target, Users, Trophy, Star, Zap } from "lucide-react";
import { useTranslation } from "react-i18next";

const features = [
  {
    icon: Globe,
    translationKey: "idea"
  },
  {
    icon: Target,
    translationKey: "vision"
  },
  {
    icon: Users,
    translationKey: "service"
  },
  {
    icon: Trophy,
    translationKey: "benefits"
  },
  {
    icon: Star,
    translationKey: "userExperience"
  },
  {
    icon: Zap,
    translationKey: "innovation"
  }
];

const WhyChooseSalaovi = () => {
  const { t } = useTranslation();

  return (
    <section className="py-20 bg-gradient-to-b from-white to-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-16 text-[#1B365D] animate-fade-in">
          {t('forAgents.whyChoose.title')}
        </h2>
        
        <div className="grid md:grid-cols-3 gap-8 md:gap-12">
          {features.map((feature, index) => {
            const Icon = feature.icon;
            return (
              <div 
                key={index}
                className="group p-6 bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1 animate-fade-in"
                style={{ animationDelay: `${index * 100}ms` }}
              >
                <div className="w-14 h-14 bg-[#1B365D] rounded-full flex items-center justify-center mb-6 group-hover:scale-110 transition-transform duration-300">
                  <Icon className="h-7 w-7 text-white" />
                </div>
                
                <h3 className="text-xl font-semibold mb-3 text-[#1B365D]">
                  {t(`forAgents.whyChoose.features.${feature.translationKey}.title`)}
                </h3>
                
                <p className="text-gray-600 leading-relaxed">
                  {t(`forAgents.whyChoose.features.${feature.translationKey}.description`)}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default WhyChooseSalaovi;
