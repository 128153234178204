
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationFI from './locales/fi.json';
import translationEN from './locales/en.json';
import translationSV from './locales/sv.json';
import translationET from './locales/et.json';
import translationDE from './locales/de.json';
import LanguageDetector from 'i18next-browser-languagedetector';

// Map of supported languages and their full locale codes and paths
const supportedLngs = {
  fi: {
    locale: 'fi-FI',
    path: 'fi/fin'
  },
  sv: {
    locale: 'sv-SE',
    path: 'sv/swe'
  },
  en: {
    locale: 'en-US',
    path: 'en/eng'
  },
  et: {
    locale: 'et-EE',
    path: 'et/est'
  },
  de: {
    locale: 'de-DE',
    path: 'de/ger'
  }
};

// Ensure translations are objects
const resources = {
  fi: {
    translation: translationFI || {}
  },
  en: {
    translation: translationEN || {}
  },
  sv: {
    translation: translationSV || {}
  },
  et: {
    translation: translationET || {}
  },
  de: {
    translation: translationDE || {}
  }
};

// Function to get language from path
const getLanguageFromPath = () => {
  const path = window.location.pathname;
  const firstSegment = path.split('/')[1]; // Get first path segment after domain
  return Object.keys(supportedLngs).find(lang => firstSegment === lang) || null;
};

// Function to get user's preferred language
const getInitialLanguage = () => {
  // Check path first
  const pathLang = getLanguageFromPath();
  if (pathLang) {
    return pathLang;
  }

  // Then check localStorage
  const storedLang = localStorage.getItem('i18nextLng');
  if (storedLang && Object.keys(supportedLngs).includes(storedLang)) {
    return storedLang;
  }

  // Finally, check browser language
  const browserLang = navigator.language.split('-')[0];
  return Object.keys(supportedLngs).includes(browserLang) ? browserLang : 'fi';
};

// Function to update URL without page reload
const updateURLLanguage = (lang: string) => {
  const currentPath = window.location.pathname;
  const segments = currentPath.split('/').filter(Boolean);
  
  // If first segment is a language code, replace it
  if (Object.keys(supportedLngs).includes(segments[0])) {
    segments[0] = supportedLngs[lang as keyof typeof supportedLngs].path;
  } else {
    // If no language in path, add it at the beginning
    segments.unshift(supportedLngs[lang as keyof typeof supportedLngs].path);
  }

  const newPath = '/' + segments.join('/');
  window.history.pushState({}, '', newPath);
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: getInitialLanguage(),
    fallbackLng: "fi",
    supportedLngs: Object.keys(supportedLngs),
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: false
    },
    returnNull: false,
    returnEmptyString: false,
    debug: process.env.NODE_ENV === 'development',
    detection: {
      order: ['path', 'localStorage', 'navigator'],
      lookupFromPathIndex: 0,
      caches: ['localStorage']
    },
    parseMissingKeyHandler: (key) => {
      console.log('Missing translation for key:', key);
      return key;
    }
  });

// Update HTML lang attribute and URL when language changes
i18n.on('languageChanged', (lng) => {
  const langConfig = supportedLngs[lng as keyof typeof supportedLngs];
  document.documentElement.setAttribute('lang', langConfig.locale);
  updateURLLanguage(lng);
  updateHreflangTags();
});

// Function to update hreflang tags
const updateHreflangTags = () => {
  // Remove existing hreflang tags
  const existingTags = document.querySelectorAll('link[rel="alternate"]');
  existingTags.forEach(tag => tag.remove());

  // Add new hreflang tags for all supported languages
  Object.entries(supportedLngs).forEach(([lang, config]) => {
    const link = document.createElement('link');
    link.rel = 'alternate';
    link.hreflang = config.locale;
    link.href = `${window.location.origin}/${config.path}${window.location.pathname.replace(/^\/[^\/]+\/[^\/]+/, '')}`;
    document.head.appendChild(link);
  });

  // Add x-default hreflang
  const defaultLink = document.createElement('link');
  defaultLink.rel = 'alternate';
  defaultLink.hreflang = 'x-default';
  defaultLink.href = `${window.location.origin}/${supportedLngs.fi.path}${window.location.pathname.replace(/^\/[^\/]+\/[^\/]+/, '')}`;
  document.head.appendChild(defaultLink);
};

export default i18n;
