
import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useToast } from "@/hooks/use-toast";
import { useForm } from "@/contexts/FormContext";
import { apiService } from "@/services/apiService";
import { MessageCircle } from "lucide-react";

interface OtpVerificationProps {
  onSuccess: () => void;
}

declare global {
  interface Window {
    dataLayer: any[];
  }
}

export const OtpVerification = ({ onSuccess }: OtpVerificationProps) => {
  const [code, setCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const { toast } = useToast();
  const { formData, updateFormData } = useForm();

  useEffect(() => {
    // Initialize dataLayer if it doesn't exist
    window.dataLayer = window.dataLayer || [];
    // Push event to dataLayer when OTP verification screen opens
    window.dataLayer.push({
      'event': 'registration_start',
      'conversion_type': 'lead'
    });
  }, []);

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [countdown]);

  const verifyOtp = async () => {
    if (!formData.accessToken || !formData.countryCode || !formData.contactNumber) {
      toast({
        title: "Virhe",
        description: "Puuttuvat tiedot OTP-vahvistusta varten",
        variant: "destructive",
      });
      return;
    }

    setIsLoading(true);
    try {
      console.log("Verifying OTP with data:", { 
        countryCode: formData.countryCode, 
        contactNumber: formData.contactNumber, 
        code 
      });
      
      const result = await apiService.verifyOtp({
        countryCode: formData.countryCode,
        contactNumber: formData.contactNumber,
        code,
      }, formData.accessToken);

      console.log("OTP verification response:", result);

      if (result.statusCode === 200) {
        // Generate a random verification ID
        const verificationId = Math.floor(100000 + Math.random() * 900000).toString();
        
        // Update form data with verification status and ID
        updateFormData({
          otpVerified: true,
          verificationId
        });

        toast({
          title: "Onnistui",
          description: "Puhelinnumero vahvistettu onnistuneesti",
        });
        
        // Track successful verification
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'otp_verified',
          'verification_id': verificationId
        });
        
        onSuccess(); // Call the success callback to proceed
      } else {
        // Display the exact error message from the API
        toast({
          title: "Virhe",
          description: result.message || "Vahvistus epäonnistui",
          variant: "destructive",
        });
      }
    } catch (error: any) {
      console.error("OTP verification error:", error);
      // Display the error message from the API response if available
      const errorMessage = error.response?.data?.message || 
                          error.message || 
                          "Vahvistus epäonnistui";
      toast({
        title: "Virhe",
        description: errorMessage,
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const resendOtp = async () => {
    if (!formData.countryCode || !formData.contactNumber) {
      toast({
        title: "Virhe",
        description: "Puuttuvat tiedot OTP:n uudelleenlähetystä varten",
        variant: "destructive",
      });
      return;
    }

    try {
      const response = await apiService.resendOtp({
        countryCode: formData.countryCode,
        contactNumber: formData.contactNumber,
      });

      console.log('Resend OTP response:', response);

      if (response.statusCode === 200) {
        setCountdown(60);
        toast({
          title: "Onnistui",
          description: "Uusi vahvistuskoodi lähetetty",
        });
      } else {
        // Display the exact error message from the API
        toast({
          title: "Virhe",
          description: response.message || "Uudelleenlähetys epäonnistui",
          variant: "destructive",
        });
      }
    } catch (error: any) {
      console.error('Resend OTP error:', error);
      // Display the error message from the API response if available
      const errorMessage = error.response?.data?.message || 
                          error.message || 
                          "Uudelleenlähetys epäonnistui";
      toast({
        title: "Virhe",
        description: errorMessage,
        variant: "destructive",
      });
    }
  };

  return (
    <div className="space-y-4" id="varmistus">
      <h2 className="text-2xl font-bold text-center">Vahvista puhelinnumerosi</h2>
      <div className="flex items-start gap-3 bg-gray-50 p-4 rounded-lg">
        <MessageCircle className="w-6 h-6 mt-1 text-blue-500" />
        <p className="text-sm text-gray-700">
          <strong>Lähetimme vahvistuskoodin puhelinumeroosi.</strong>{" "}
          Syötä tekstiviestillä saamasi kuusinumeroinen koodi alle.
        </p>
      </div>
      <div className="space-y-4">
        <Input
          type="text"
          placeholder="6 numeroinen vahvistuskoodi"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          className="text-center text-2xl tracking-widest"
          maxLength={6}
        />
        <div className="flex flex-col gap-2">
          <Button
            onClick={verifyOtp}
            disabled={isLoading || code.length !== 6}
            className="w-full bg-[#1B365D] hover:bg-[#152C4A]"
          >
            {isLoading ? "Vahvistetaan..." : "Vahvista"}
          </Button>
          <Button
            variant="outline"
            onClick={resendOtp}
            disabled={countdown > 0 || isLoading}
            className="w-full"
          >
            {countdown > 0 ? `Odota ${countdown}s` : "Tilaa uusi koodi"}
          </Button>
        </div>
      </div>
    </div>
  );
};
