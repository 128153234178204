
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import type { FormState } from "@/utils/formUtils";
import { useState } from "react";
import { useToast } from "@/hooks/use-toast";
import { useTranslation } from "react-i18next";

const countryCodes = [
  { code: "+358", country: "FI", name: "Finland" },
  { code: "+46", country: "SE", name: "Sweden" },
  { code: "+47", country: "NO", name: "Norway" },
  { code: "+45", country: "DK", name: "Denmark" },
  { code: "+372", country: "EE", name: "Estonia" }
];

interface ContactFormFieldsProps {
  formState: FormState;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const ContactFormFields = ({ formState, onChange }: ContactFormFieldsProps) => {
  const [countryCode, setCountryCode] = useState("+358");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const { toast } = useToast();
  const { t } = useTranslation();

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone: string) => {
    // Remove all non-digit characters for validation
    const digitsOnly = phone.replace(/\D/g, '');
    // Finnish phone numbers are typically 8-12 digits (excluding country code)
    return digitsOnly.length >= 8 && digitsOnly.length <= 12;
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Remove any existing country code prefix
    let phoneNumber = e.target.value.replace(/^\+\d+\s*/, '');
    
    // Only allow digits
    phoneNumber = phoneNumber.replace(/\D/g, '');
    
    // Remove leading zero if present
    phoneNumber = phoneNumber.replace(/^0/, '');
    
    // Validate phone number
    if (phoneNumber && !validatePhone(phoneNumber)) {
      setErrors(prev => ({
        ...prev,
        phone: t('contactForm.errors.phone')
      }));
    } else {
      setErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors.phone;
        return newErrors;
      });
    }
    
    const syntheticEvent = {
      ...e,
      target: {
        ...e.target,
        name: 'phone',
        value: phoneNumber
      }
    };
    
    onChange(syntheticEvent);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    
    if (email && !validateEmail(email)) {
      setErrors(prev => ({
        ...prev,
        email: t('contactForm.errors.email')
      }));
    } else {
      setErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors.email;
        return newErrors;
      });
    }
    
    onChange(e);
  };

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-2">
          <Label htmlFor="name" className="text-gray-700">
            {t('contactForm.name.label')} *
          </Label>
          <Input
            id="name"
            name="name"
            placeholder={t('contactForm.name.placeholder')}
            value={formState.name}
            onChange={onChange}
            className="h-12 px-4 text-base transition-all duration-200 focus:ring-2 focus:ring-brand-blue"
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="size" className="text-gray-700">
            {t('contactForm.size.label')} *
          </Label>
          <div className="relative">
            <Input
              id="size"
              name="size"
              type="number"
              placeholder={t('contactForm.size.placeholder')}
              value={formState.size}
              onChange={onChange}
              className="h-12 px-4 text-base transition-all duration-200 focus:ring-2 focus:ring-brand-blue pr-12"
              min="1"
              max="1000"
            />
            <span className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-500 pointer-events-none">
              m²
            </span>
          </div>
        </div>

        <div className="space-y-2">
          <Label htmlFor="phone" className="text-gray-700">
            {t('contactForm.phone.label')} *
          </Label>
          <div className="flex gap-2">
            <Select 
              defaultValue="+358"
              onValueChange={setCountryCode}
            >
              <SelectTrigger className="w-[120px] h-12 flex items-center gap-2 bg-white">
                <div className="flex items-center gap-2">
                  <img 
                    src={`https://flagcdn.com/w20/${countryCodes.find(c => c.code === countryCode)?.country.toLowerCase()}.png`}
                    alt={countryCodes.find(c => c.code === countryCode)?.name}
                    className="w-5 h-auto"
                  />
                  <span className="text-sm">{countryCode}</span>
                </div>
              </SelectTrigger>
              <SelectContent className="bg-white">
                {countryCodes.map((country) => (
                  <SelectItem 
                    key={country.code} 
                    value={country.code}
                    className="flex items-center gap-2 py-3 cursor-pointer"
                  >
                    <img 
                      src={`https://flagcdn.com/w20/${country.country.toLowerCase()}.png`}
                      alt={country.name}
                      className="w-5 h-auto"
                    />
                    <span className="text-sm font-medium">{country.code}</span>
                    <span className="text-sm text-gray-500 ml-1">({country.name})</span>
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <Input
              id="phone"
              name="phone"
              type="tel"
              placeholder={t('contactForm.phone.placeholder')}
              value={formState.phone}
              onChange={handlePhoneChange}
              className={`flex-1 h-12 px-4 text-base transition-all duration-200 focus:ring-2 focus:ring-brand-blue ${
                errors.phone ? 'border-red-500' : ''
              }`}
              pattern="[0-9]*"
              inputMode="numeric"
            />
          </div>
          {errors.phone && (
            <p className="text-xs text-red-500 mt-1">{errors.phone}</p>
          )}
        </div>

        <div className="space-y-2">
          <Label htmlFor="email" className="text-gray-700">
            {t('contactForm.email.label')} *
          </Label>
          <Input
            id="email"
            name="email"
            type="email"
            placeholder={t('contactForm.email.placeholder')}
            value={formState.email}
            onChange={handleEmailChange}
            className={`h-12 px-4 text-base transition-all duration-200 focus:ring-2 focus:ring-brand-blue ${
              errors.email ? 'border-red-500' : ''
            }`}
          />
          {errors.email && (
            <p className="text-xs text-red-500 mt-1">{errors.email}</p>
          )}
        </div>
      </div>

      <div className="space-y-2 mt-6">
        <Label htmlFor="additionalInfo" className="text-gray-700">
          {t('contactForm.additionalInfo.label')}
        </Label>
        <Textarea
          id="additionalInfo"
          name="additionalInfo"
          placeholder={t('contactForm.additionalInfo.placeholder')}
          value={formState.additionalInfo}
          onChange={onChange}
          className="min-h-[120px] px-4 py-3 text-base transition-all duration-200 focus:ring-2 focus:ring-brand-blue resize-y"
        />
      </div>
    </>
  );
};
