
const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-white pt-24 pb-16">
      <div className="container max-w-4xl mx-auto px-4">
        <h1 className="text-3xl font-bold mb-8">Tietosuojakäytäntö</h1>
        
        <div className="space-y-8">
          <section>
            <h2 className="text-xl font-semibold mb-4">1. Tietosuojavastaava ja rekisterinpitäjä</h2>
            <div className="text-gray-700 space-y-1">
              <p>Yritys: Salaovi Oy</p>
              <p>Y-tunnus: 3164381-8</p>
              <p>Puhelinnumero: +358 045 271 7731</p>
              <p>Sähköposti: tuki@salaovi.fi</p>
            </div>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">2. Rekisterin käyttötarkoitus</h2>
            <p className="text-gray-700">
              Kerättäviä henkilötietoja käytetään:
              Asiakkaan tunnistautumiseen ja käyttöoikeuksien hallintaan. Rekisteröityneiden käyttäjien tilausten toimittamiseen ja asiakassuhteen ylläpitoon sekä kehittämiseen.
            </p>
            <p className="text-gray-700 mt-4">
              Toimintamme pohjautuu lailliseen liiketoimintaan, joten noudatamme myös EU:n lainsäädäntöä henkilötietojen tallentamisesta.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">3. Tietojen keräämisen ja käsittelyn peruste</h2>
            <p className="text-gray-700">
              Asiakkaan tietoja kerätään ja käsitellään asiakkaan suostumuksella, tai asiakkaan kanssa tehtävän sopimuksen täytäntöön panemiseksi.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">4. Rekisterin tietosisältö</h2>
            <p className="text-gray-700">
              Osoite, Nimi, Sähköpostiosoite, Puhelinnumero, Käyttäjätunnus sekä asiakkuuteen muut liittyvät tiedot
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">5. Tietojen säilytysaika</h2>
            <p className="text-gray-700">
              Henkilötiedot säilytetään niin kauan, kun niitä tarvitaan asiakkaan kanssa tehtävän sopimuksen täytäntöönpanemiseksi tai asiakaspalvelun kehittämiseksi.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">6. Säännönmukaiset tietolähteet</h2>
            <p className="text-gray-700">
              Rekisteriin kerätään tietoja:
              Henkilöltä itseltään. Viranomaisen pitämistä rekistereistä lain sallimissa rajoissa (esim. ytj.fi). Tietoja kerätään myös Google Analytics –analytiikkatyökalun avulla.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">7. Säännönmukaiset tietojen luovutukset ja tietojen siirto EU:n tai Euroopan talousalueen ulkopuolelle</h2>
            <p className="text-gray-700">
              Tietoja ei säännönmukaisesti luovuteta Eu alueen ulkopuolelle. Osa yrityksen käyttämistä ulkopuolisista palvelun- tai ohjelmistontarjoajista saattavat säilyttää tietoja EU:n tai Euroopan talousalueen ulkopuolella.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">8. Evästeiden (cookies) käyttö</h2>
            <p className="text-gray-700">
              Käytämme sivuillamme ns. cookie-toimintoa eli evästeitä. Eväste on pieni, käyttäjän tietokoneelle lähetettävä ja siellä säilytettävä tekstitiedosto, joka mahdollistaa internet-sivujen ylläpitäjän tunnistamaan usein sivuilla vierailevat kävijät, helpottamaan kävijöiden kirjautumista sivuille sekä mahdollistamaan yhdistelmätiedon laatimisen kävijöistä. Tämän palautteen avulla pystymme jatkuvasti parantamaan sivujemme sisältöä.
            </p>
            <p className="text-gray-700 mt-4">
              Evästeet eivät vahingoita käyttäjien tietokoneita tai tiedostoja. Käytämme niitä siten, että voimme tarjota asiakkaillemme kunkin yksilöityjen tarpeiden mukaisia tietoja ja palveluita.
            </p>
            <p className="text-gray-700 mt-4">
              Mikäli sivuillamme vieraileva käyttäjä ei halua meidän saavan edellä mainittuja tietoja evästeiden avulla, useimmat selainohjelmat mahdollistavat eväste-toiminnon poiskytkemisen. Tämä tapahtuu yleensä selaimen asetuksien kautta.
            </p>
            <p className="text-gray-700 mt-4">
              On kuitenkin hyvä ottaa huomioon se, että evästeet voivat olla tarpeellisia joidenkin ylläpitämiemme sivujen ja tarjoamiemme palveluiden asianmukaiselle toimimiselle.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">9. Rekisterin suojaus</h2>
            <div className="text-gray-700 space-y-2">
              <p>Tiedot siirretään SSL-suojatun yhteyden ylitse.</p>
              <p>Sähköiset tiedot on suojattu palomuurilla, käyttäjätunnuksin ja salasanoin.</p>
              <p>Tietojen käyttöoikeus on vain niillä rekisterinpitäjän palveluksessa olevilla henkilöillä, jotka tarvitsevat tietoja tehtävissään.</p>
            </div>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">10. Automaattinen päätöksenteko</h2>
            <p className="text-gray-700">
              Automatisoituja yksittäispäätöksiä (EU:n tietosuoja-asetuksen artikla 22) ei tehdä.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">11. Rekisteröidyn oikeudet</h2>
            <div className="text-gray-700 space-y-4">
              <p>
                Rekisteröidyllä on oikeus tarkastaa, mitä häntä koskevia tietoja henkilötietorekisteriin on talletettu. Kirjallinen tarkastuspyyntö tulee lähettää allekirjoitettuna rekisteriasioista vastaavalle henkilölle.
              </p>
              <p>
                Tarkastusoikeus on maksuton enintään kerran vuodessa toteutettuna.
              </p>
              <p>
                Rekisteröidyllä on oikeus vaatia virheellisten tai vanhentuneiden tietojen oikaisua tai poistamista tai tietojen siirtoa järjestelmästä toiseen. Hänellä on myös oikeus rajoittaa tai vastustaa tietojensa käsittelyä EU:n tietosuoja-asetuksen artiklojen 18 ja 21 mukaisesti.
              </p>
              <p>
                Rekisteröidyllä on oikeus peruuttaa aiemmin antamansa suostumus tietojen käsittelylle tai tehdä henkilötietojensa käsittelyyn liittyvistä seikoista valitus valvontaviranomaiselle.
              </p>
              <p>
                Rekisteröidyllä on myös oikeus kieltää tietojensa käyttö suoramarkkinointitarkoituksiin.
              </p>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
