
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { 
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Mail, Phone, Loader2, Clock } from "lucide-react";
import { useForm } from "@/contexts/FormContext";
import { useTranslation } from "react-i18next";

interface ContactFormProps {
  email: string;
  phone: string;
  onEmailChange: (email: string) => void;
  onPhoneChange: (phone: string) => void;
  onSubmit: () => void;
  isCalculating: boolean;
}

type SalesTimeline = 'asap' | '1month' | '2months' | '6months' | 'not_selling';

export function ContactForm({ 
  email, 
  phone, 
  onEmailChange, 
  onPhoneChange, 
  onSubmit, 
  isCalculating 
}: ContactFormProps) {
  const { formData, updateFormData } = useForm();
  const { t } = useTranslation();

  const handleSalesTimelineChange = (value: SalesTimeline) => {
    updateFormData({ salesTimeline: value });
  };

  return (
    <div className="space-y-6">
      <div className="space-y-4">
        <div>
          <Label htmlFor="email" className="text-gray-700 flex items-center gap-2">
            <Mail className="h-4 w-4" />
            {t('hintaArvio.form.contact.email.label')}
          </Label>
          <Input
            id="email"
            type="email"
            value={email}
            onChange={(e) => onEmailChange(e.target.value)}
            placeholder={t('hintaArvio.form.contact.email.placeholder')}
            className="mt-2"
            required
          />
        </div>

        <div>
          <Label htmlFor="phone" className="text-gray-700 flex items-center gap-2">
            <Phone className="h-4 w-4" />
            {t('hintaArvio.form.contact.phone.label')}
          </Label>
          <Input
            id="phone"
            type="tel"
            value={phone}
            onChange={(e) => onPhoneChange(e.target.value)}
            placeholder={t('hintaArvio.form.contact.phone.placeholder')}
            className="mt-2"
            required
          />
        </div>

        <div>
          <Label className="text-gray-700 flex items-center gap-2">
            <Clock className="h-4 w-4" />
            {t('hintaArvio.form.contact.timeline.label')}
          </Label>
          <Select 
            onValueChange={handleSalesTimelineChange} 
            required
          >
            <SelectTrigger className="w-full mt-2">
              <SelectValue placeholder={t('hintaArvio.form.contact.timeline.placeholder')} />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="asap">{t('hintaArvio.form.contact.timeline.options.asap')}</SelectItem>
              <SelectItem value="1month">{t('hintaArvio.form.contact.timeline.options.1month')}</SelectItem>
              <SelectItem value="2months">{t('hintaArvio.form.contact.timeline.options.2months')}</SelectItem>
              <SelectItem value="6months">{t('hintaArvio.form.contact.timeline.options.6months')}</SelectItem>
              <SelectItem value="not_selling">{t('hintaArvio.form.contact.timeline.options.not_selling')}</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>

      <Button
        onClick={onSubmit}
        className="w-full h-12"
        disabled={!email || !phone || !formData.salesTimeline || isCalculating}
      >
        {isCalculating ? (
          <>
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            {t('hintaArvio.form.contact.calculating')}
          </>
        ) : (
          t('hintaArvio.form.contact.calculateButton')
        )}
      </Button>

      <p className="text-sm text-gray-500 text-center">
        {t('hintaArvio.form.contact.emailNotice')}
      </p>
    </div>
  );
}
