
import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";

export const AppPreview = () => {
  const { t } = useTranslation();

  return (
    <section className="py-20 px-4 bg-white">
      <div className="max-w-7xl mx-auto grid md:grid-cols-2 gap-8 items-center">
        <div className="relative">
          <img 
            src="/lovable-uploads/6848d56e-e876-46f1-b692-4f622d830b1d.png" 
            alt="Salaovi app interface" 
            className="w-full rounded-lg shadow-2xl"
          />
        </div>
        
        <div className="space-y-6">
          <h2 className="text-4xl font-bold text-[#1B365D] leading-tight">
            {t('appPreview.title')}
          </h2>
          
          <p className="text-gray-600 text-lg">
            {t('appPreview.description')}
          </p>

          <div className="space-y-4">
            <Button 
              className="w-full md:w-auto bg-[#1B365D] hover:bg-[#152C4A] text-white px-8 py-6 rounded-lg text-lg" 
              onClick={() => window.scrollTo({
                top: 0,
                behavior: 'smooth'
              })}
            >
              {t('appPreview.startComparison')}
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};
