
const TermsOfService = () => {
  return (
    <div className="min-h-screen bg-white pt-24 pb-16">
      <div className="container max-w-4xl mx-auto px-4">
        <h1 className="text-3xl font-bold mb-8">Palvelun käyttöehdot</h1>
        
        <section className="mb-12">
          <h2 className="text-xl font-semibold mb-4">Yhteistyösopimus lyhyesti</h2>
          
          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-medium mb-2">1. Palvelun tarkoitus</h3>
              <p className="text-gray-700">
                Palvelun kautta saat tarjouspyyntöjä asiakkaista, jotka suunnittelevat asuntonsa myyntiä toimialueellasi. Tarjouspyynnön saapumisen jälkeen sinulla on yksi tunti aikaa muokata tarjoustasi. Mikäli et muokkaa tarjousta osallistut tarjouskilpaan vakiotarjouksella. Olet mukana jokaisella tarjouskierroksella josta sinulle tulee ilmoitus. Asiakkaalle valikoituminen tarkoittaa, että Salovi valitsi tarjouksesi 4 parhaan joukkoon asiakkaalle ja voitte sen jälkeen olla yhteydessä asiakkaan kanssa ja sopia mahdollisen hankintakäynnin. Asiakas voi kuitenkin valita välittäjänsä suoraan sovelluksesta ilman hankintakäyntiä.
              </p>
            </div>

            <div>
              <h3 className="text-lg font-medium mb-2">2. Valikoitumisperuste ja hinta</h3>
              <p className="text-gray-700">
                Olet mukana jokaisella alueesi tarjouskierroksella vakiotarjouksellasi. Sinulla on 1h aikaa muokata tarjousta sen saapumisen jälkeen. Asiakkaalle valikoituminen maksaa 25€. Mikäli vinkki johtaa kauppaan veloitetaan 7% komission palkkiostasi. Ilmoittamatta jäteyistä toimeksiannoista ja myynneistä peritään 500€ vakiokorvaus. Hintoihin lisätään ALV 24%. Valikoituminen veloitetaan automaattisesti maksutavaltasi. Saat tiedon valikoitumisesta sovellukseen sekä sähköpostiin. Muista tarkastaa, että viestit eivät mene roskapostiin.
              </p>
            </div>

            <div>
              <h3 className="text-lg font-medium mb-2">3. Hyvitysperusteet ja sopimuksen kesto</h3>
              <p className="text-gray-700">
                Jokainen toimitettu asiakas on vinkki. Emme takaa jokaiselle vinkille hankintakäyntiä tai asiakkaan tavoittamista mutta pyrimme kaikin keinoin siihen. Maksu hyvitetään teille sovellukseen kuitenkin aina mikäli asiakas ei olekaan myymässä asuntoaan. Palveluun liittyessä sitoudut osallistumaan tarjouskilpailuihin koko alueellasi. Tämän sopimuksen irtisanimisaika on 7 päivää.
              </p>
            </div>
          </div>
        </section>

        <section className="mb-12">
          <h2 className="text-xl font-semibold mb-4">Palvelun käyttöehdot asiakkaille</h2>
          
          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-medium mb-2">Välittäjävertailun ehdot</h3>
              <p className="text-gray-700">
                Salaovi palvelu vertailee sinulle alueeltasi 3-5 parasta laillistettua kiinteistönvälittäjää jotka täyttävät kriteerit, täysin ilmaiseksi. Välittäjät ottavat sinuun yhteyttä ja saat kaikilta parhaan kilpailukykyisen tarjouksen. Kilpailutuksen aloittamisen ehtona on vain että olet aloittamassa asuntosi myymistä lähiaikoina. Sinulle jää tämän jälkeen enää tehtäväksi valita näistä juuri sinulle sopivin. Kilpailutus ei sido sinua mihinkään ja on täysin maksuton.
              </p>
            </div>

            <div>
              <h3 className="text-lg font-medium mb-2">Hinta-arvio ehdot</h3>
              <p className="text-gray-700">
                Palvelun arvio perustuu tilastokeskuksen julkisiin toteutuneisiin neliöhintoihin alueella. Salaovi.fi ei vastaa tietojen oikeellisuudesta mutta pyrimme tuottamaan parhaan mahdollisen arvion. Palvelu ei sido sinua mihinkään ja on täysin ilmainen.
              </p>
            </div>

            <div>
              <h3 className="text-lg font-medium mb-2">Henkilötietojen käsittely</h3>
              <p className="text-gray-700">
                Palvelussa kerättäviä henkilötietoja käsitellessä noudatetaan henkilötietolakia ja käsittelemme tietoja sen mukaisesti. Tiedot ovat osa yrityksen asiakasrekisteriä. Tietoja voidaan käyttää palveluiden suoramarkkinointiin.
              </p>
            </div>
          </div>
        </section>

        {/* ... Additional sections for the full terms of service ... */}
        
      </div>
    </div>
  );
};

export default TermsOfService;
