
import { supabase } from "@/integrations/supabase/client";
import { wordpressImportService } from "./wordpressImportService";
import type { BlogPost } from "@/types/blog";

export const blogService = {
  async getBlogPosts() {
    const { data, error } = await supabase
      .from('blog_posts')
      .select(`
        id,
        title,
        excerpt,
        slug,
        published_at,
        featured_image,
        categories (
          id,
          name,
          slug
        )
      `)
      .order('published_at', { ascending: false });
    
    if (error) {
      console.error('Error fetching blog posts:', error);
      throw error;
    }
    
    return data;
  },

  async updateRandomFeaturedImages() {
    try {
      // First, get all images from the bucket
      const { data: bucketImages, error: bucketError } = await supabase
        .storage
        .from('blog_images')
        .list();

      if (bucketError || !bucketImages) {
        console.error('Error fetching bucket images:', bucketError);
        throw new Error('Failed to fetch images from storage bucket');
      }

      if (bucketImages.length === 0) {
        throw new Error('No images found in the blog_images bucket');
      }

      // Get all blog posts
      const { data: posts, error: postsError } = await supabase
        .from('blog_posts')
        .select('id');

      if (postsError || !posts) {
        console.error('Error fetching posts:', postsError);
        throw new Error('Failed to fetch blog posts');
      }

      if (posts.length === 0) {
        throw new Error('No blog posts found to update');
      }

      // Create an array of public URLs first
      const imageUrls = bucketImages.map(img => {
        const { data } = supabase.storage
          .from('blog_images')
          .getPublicUrl(img.name);
        return data.publicUrl;
      }).filter(url => url); // Filter out any undefined URLs

      console.log(`Found ${imageUrls.length} valid images in bucket`);

      if (imageUrls.length === 0) {
        throw new Error('No valid image URLs generated from bucket');
      }

      // Update each post with a random image
      const updatePromises = posts.map(async (post) => {
        const randomIndex = Math.floor(Math.random() * imageUrls.length);
        const randomImageUrl = imageUrls[randomIndex];
        
        if (!randomImageUrl) {
          console.error(`No valid image URL for post ${post.id}`);
          return;
        }

        console.log(`Updating post ${post.id} with image ${randomImageUrl}`);
        
        const { error: updateError } = await supabase
          .from('blog_posts')
          .update({ featured_image: randomImageUrl })
          .eq('id', post.id);

        if (updateError) {
          console.error(`Error updating post ${post.id}:`, updateError);
        }
      });

      // Wait for all updates to complete
      await Promise.all(updatePromises);

      return {
        success: true,
        message: `Updated ${posts.length} posts with random featured images`
      };
    } catch (error) {
      console.error('Error in updateRandomFeaturedImages:', error);
      throw error;
    }
  },

  uploadWordPressFile: wordpressImportService.importWordPressFile
};

export type { BlogPost } from "@/types/blog";
