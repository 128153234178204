
import type { PlaceResult } from "@/hooks/useGoogleMaps";

export interface FormState {
  name: string;
  size: string;
  phone: string;
  email: string;
  additionalInfo: string;
}

export interface Address {
  address?: PlaceResult;
  countryCode?: string;
  streetViewPov?: {
    heading: number;
    pitch: number;
  };
}

export const validateForm = (formState: FormState) => {
  return !(!formState.name || !formState.size || !formState.phone || !formState.email);
};

export const createRegistrationData = (formState: FormState, address: Address) => {
  // Format phone number - remove spaces and any country code prefix
  let phone = formState.phone.replace(/\s/g, '');
  if (phone.startsWith('+')) {
    // Remove any country code prefix (e.g., +358)
    phone = phone.replace(/^\+\d+/, '');
  }
  // Remove leading zero if present
  phone = phone.replace(/^0/, '');

  // Ensure size is a number
  const size = parseInt(formState.size, 10);
  if (isNaN(size)) {
    throw new Error("Invalid size value");
  }

  return {
    fullName: formState.name.trim(),
    email: formState.email.trim().toLowerCase(),
    contactNumber: phone,
    countryCode: address.countryCode || "+358",
    password: generateRandomString(8),
    lat: address.address?.latitude?.toString() || "",
    lng: address.address?.longitude?.toString() || "",
    type: "CUSTOMER",
    deviceToken: "1",
  };
};

export const createEnquiryData = (formState: FormState, address: PlaceResult) => {
  console.log('Creating enquiry data with address and POV:', address);

  // Ensure we have a city from the address components
  const city = address.components.locality;
  if (!city) {
    console.error('No city found in address components:', address.components);
    throw new Error("City is required for enquiry");
  }

  // Generate Street View URL with the current POV
  const generateStreetViewUrl = (place: PlaceResult) => {
    const baseUrl = `https://maps.googleapis.com/maps/api/streetview?location=${place.latitude},${place.longitude}&size=1200x456`;
    
    // Always include POV values in URL
    const heading = place.streetViewPov?.heading ?? 0;
    const pitch = place.streetViewPov?.pitch ?? 0;
    
    console.log('Generating Street View URL with POV:', { heading, pitch });
    
    return `${baseUrl}&heading=${heading}&pitch=${pitch}&key=AIzaSyAkfhQ_hKSnM3dBcPPWA4RT_YCtfusjUMc`;
  };
  
  const streetViewUrl = generateStreetViewUrl(address);
  console.log('Generated Street View URL:', streetViewUrl);

  const enquiryData = {
    name: formState.name.trim(),
    email: formState.email.trim().toLowerCase(),
    contactNumber: formState.phone.trim(),
    address: address.address,
    lat: address.latitude?.toString() || "",
    lng: address.longitude?.toString() || "",
    city: city,
    zipCode: address.components.postal_code || "",
    size: formState.size.toString(),
    images: [streetViewUrl],
    comment: formState.additionalInfo?.trim() || "Ei lisätietoja"
  };

  console.log('Generated enquiry data:', enquiryData);
  return enquiryData;
};

export const generateRandomString = (length: number): string => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
