
import { useEffect, useRef, useState } from "react";
import { useGoogleMaps, PlaceResult } from "@/hooks/useGoogleMaps";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useToast } from "@/hooks/use-toast";
import { useTranslation } from "react-i18next";

interface AddressInputProps {
  onAddressSelect: (place: PlaceResult) => void;
  className?: string;
  placeholder?: string;
}

export const AddressInput = ({ onAddressSelect, className, placeholder }: AddressInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { isLoaded, initAutocomplete } = useGoogleMaps();
  const { toast } = useToast();
  const [inputValue, setInputValue] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    if (isLoaded && inputRef.current) {
      initAutocomplete(inputRef.current, (place: PlaceResult | null) => {
        if (place) {
          if (!place.components.street_number) {
            toast({
              title: "Virheellinen osoite",
              description: "Valitse tarkka katuosoite numeroineen",
              variant: "destructive",
            });
            setInputValue("");
            return;
          }

          // Add default Street View POV settings
          const placeWithPov: PlaceResult = {
            ...place,
            streetViewPov: {
              heading: 0,
              pitch: 0
            }
          };

          onAddressSelect(placeWithPov);
          setInputValue(place.address);
        }
      });

      // Add custom styles to Google Places Autocomplete dropdown
      const style = document.createElement('style');
      style.textContent = `
        .pac-container {
          border-radius: 0.5rem;
          border: 1px solid #E2E8F0;
          box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
          margin-top: 4px;
          background-color: white;
          font-family: 'Inter', sans-serif;
          padding: 0.5rem 0;
          z-index: 1000;
        }
        
        .pac-item {
          padding: 0.75rem 1rem;
          font-size: 0.875rem;
          line-height: 1.25rem;
          color: #1a202c;
          border: none;
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }
        
        .pac-item:hover {
          background-color: #F7FAFC;
        }
        
        .pac-item-selected {
          background-color: #EDF2F7;
        }
        
        .pac-icon {
          width: 20px;
          height: 20px;
          margin-right: 8px;
          opacity: 0.6;
        }
        
        .pac-item-query {
          font-size: 0.875rem;
          font-weight: 500;
          color: #2D3748;
        }
        
        .pac-matched {
          font-weight: 600;
          color: #1B365D;
        }
        
        .pac-item > span:not(.pac-icon):not(.pac-item-query) {
          font-size: 0.75rem;
          color: #718096;
          margin-left: 0.25rem;
        }
      `;
      document.head.appendChild(style);
    }
  }, [isLoaded, onAddressSelect]);

  return (
    <div className="space-y-2">
      <Label htmlFor="address">{t('hero.addressPlaceholder')}</Label>
      <Input
        ref={inputRef}
        id="address"
        type="text"
        placeholder={placeholder || t('hero.addressPlaceholder')}
        className={`h-12 px-4 text-base transition-all duration-200 focus:ring-2 focus:ring-brand-blue ${className}`}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
      />
      <p className="text-sm text-gray-500">
        {t('hero.addressHelper')}
      </p>
    </div>
  );
};
