
import { ArrowRight } from "lucide-react";
import { useTranslation } from "react-i18next";

export const HeroSection = () => {
  const { t } = useTranslation();

  return (
    <section 
      className="relative bg-[#1B365D] text-white py-20 min-h-[600px] flex items-center"
      style={{
        backgroundImage: 'url("/lovable-uploads/cabeeed6-6dac-4f8e-aa16-870d196f73ad.png")',
        backgroundSize: 'cover',
        backgroundPosition: 'center right',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <div className="absolute inset-0 bg-[#1B365D]/80"></div>
      <div className="container mx-auto px-4 relative z-10">
        <div className="max-w-2xl">
          <h1 className="text-4xl md:text-5xl font-bold mb-6 animate-fade-in">
            {t('forAgents.hero.title')}
          </h1>
          <p className="text-lg mb-8 animate-fade-in" style={{ animationDelay: "200ms" }}>
            {t('forAgents.hero.description')}
          </p>
          <div className="flex gap-4 animate-fade-in" style={{ animationDelay: "400ms" }}>
            <a 
              href="https://partners.salaovi.fi/register"
              className="inline-flex items-center gap-2 bg-white text-[#1B365D] px-6 py-3 rounded-lg font-medium hover:bg-gray-100 transition-colors"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('forAgents.hero.buttons.startNow')} <ArrowRight className="h-5 w-5" />
            </a>
            <a 
              href="#lisatietoa"
              className="inline-flex items-center gap-2 border border-white text-white px-6 py-3 rounded-lg font-medium hover:bg-white/10 transition-colors"
            >
              {t('forAgents.hero.buttons.readMore')}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
