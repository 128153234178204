
import { Card, CardHeader, CardContent } from "@/components/ui/card";
import { Check } from "lucide-react";
import { useForm } from "@/contexts/FormContext";

export const ThankYou = () => {
  const { formData } = useForm();
  
  return (
    <div className="space-y-6 max-w-2xl mx-auto">
      <div className="text-center space-y-2">
        <div className="flex justify-center">
          <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center">
            <Check className="w-8 h-8 text-green-600" />
          </div>
        </div>
        <h1 className="text-3xl font-bold text-[#1B365D]">Vertailu aloitettu</h1>
        <div className="inline-block bg-green-100 px-4 py-1 rounded-full">
          <p className="text-green-800">Vertailutunnus #{formData.verificationId || '000000'}</p>
        </div>
      </div>

      <Card className="border-none shadow-lg">
        <CardHeader>
          <h2 className="text-xl font-semibold text-center text-[#1B365D]">
            Kiitos tilauksestasi!
          </h2>
        </CardHeader>
        <CardContent className="space-y-6">
          <p className="text-center text-gray-700">
            Alueen huippuvälittäjät jättävät sinulle nyt tarjouksia, ja saat parhaat tarjoukset suoraan sähköpostiisi tunnin päästä.
            Suosittelemme vastaanottamaan vielä välittäjiltä tarkat tarjoukset henkilökohtaisesti, jotta pystyt tehdä parhaan päätöksen.
          </p>

          <div className="bg-green-50 p-4 rounded-lg">
            <div className="flex gap-2 items-start">
              <Check className="w-5 h-5 text-green-600 mt-1 flex-shrink-0" />
              <p className="text-sm text-green-800">
                <strong>HUOM! Jotta palvelu toimii odotetulla tavalla, älä tilaa vertailupalvelua muualta.</strong>{" "}
                Tavoitat kauttamme kaikki alueen parhaat välittäjät
              </p>
            </div>
          </div>

          <div className="space-y-4">
            <div className="space-y-2">
              <h3 className="text-center text-[#1B365D] font-semibold">Yhteistyössä</h3>
              <img 
                src="/lovable-uploads/de9c9061-9ab7-4822-bdf8-9974637e176f.png" 
                alt="Partner Logos" 
                className="w-full max-w-3xl mx-auto"
              />
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};
