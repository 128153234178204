
import { ArrowRight } from "lucide-react";
import { useTranslation } from "react-i18next";

export const FinalCTA = () => {
  const { t } = useTranslation();

  return (
    <section className="py-20 bg-[#1B365D] text-white text-center">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold mb-6">{t('forAgents.finalCta.title')}</h2>
        <p className="text-lg mb-8 max-w-2xl mx-auto">
          {t('forAgents.finalCta.description')}
        </p>
        <a 
          href="https://partners.salaovi.fi/register"
          className="inline-flex items-center gap-2 bg-white text-[#1B365D] px-8 py-4 rounded-lg font-medium hover:bg-gray-100 transition-colors text-lg"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('forAgents.finalCta.button')} <ArrowRight className="h-6 w-6" />
        </a>
      </div>
    </section>
  );
};
