
import { supabase } from "@/integrations/supabase/client";

export const imageService = {
  async downloadAndUploadImage(imageUrl: string): Promise<string | null> {
    try {
      // Skip invalid URLs
      if (!imageUrl || !imageUrl.startsWith('http')) {
        console.log('Skipping invalid image URL:', imageUrl);
        return null;
      }

      // Download the image
      const response = await fetch(imageUrl);
      if (!response.ok) {
        console.log(`Skipping image - HTTP ${response.status}: ${imageUrl}`);
        return null;
      }

      const contentType = response.headers.get('content-type');
      if (!contentType?.startsWith('image/')) {
        console.log('Skipping non-image content type:', contentType);
        return null;
      }

      const blob = await response.blob();
      if (blob.size === 0) {
        console.log('Skipping empty image:', imageUrl);
        return null;
      }

      const fileExt = imageUrl.split('.').pop()?.split('?')[0] || 'jpg';
      const fileName = `${crypto.randomUUID()}.${fileExt}`;

      // Upload to Supabase storage
      const { data, error } = await supabase.storage
        .from('blog-images')
        .upload(fileName, blob, {
          contentType: blob.type,
          upsert: false
        });

      if (error) {
        console.log('Failed to upload image, skipping:', error.message);
        return null;
      }

      // Get public URL
      const { data: { publicUrl } } = supabase.storage
        .from('blog-images')
        .getPublicUrl(fileName);

      console.log('Successfully processed image:', imageUrl);
      return publicUrl;
    } catch (error) {
      console.log('Error processing image, skipping:', imageUrl, error);
      return null;
    }
  }
};
