
import { Activity, ThumbsUp, Clock, Users } from "lucide-react";
import { useTranslation } from "react-i18next";

const metrics = [
  {
    icon: ThumbsUp,
    key: "satisfaction"
  },
  {
    icon: Clock,
    key: "responseTime"
  },
  {
    icon: Activity,
    key: "savings"
  },
  {
    icon: Users,
    key: "soldHomes"
  }
];

export const Stats = () => {
  const { t } = useTranslation();

  return (
    <section className="py-20 px-4 bg-[#1B365D] bg-opacity-[0.02] relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-br from-[#1B365D]/5 to-transparent" />
      <div className="max-w-7xl mx-auto relative z-10">
        <div className="text-center mb-16 animate-fade-in">
          <h2 className="text-3xl md:text-4xl font-bold mb-4 text-[#1B365D]">
            {t('stats.title')}
          </h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            {t('stats.description')}
          </p>
        </div>
        
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {metrics.map((metric, index) => (
            <div 
              key={index} 
              className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1 animate-fade-in border border-[#1B365D]/10"
              style={{ animationDelay: `${index * 200}ms` }}
            >
              <div className="flex items-center justify-center mb-6">
                <div className="p-3 rounded-full bg-[#1B365D]/5">
                  <metric.icon className="w-10 h-10 text-[#1B365D]" />
                </div>
              </div>
              <div className="text-center">
                <div className="text-5xl font-bold text-[#1B365D] mb-3 tracking-tight">
                  {t(`stats.metrics.${metric.key}.value`)}
                </div>
                <div className="font-semibold text-gray-900 mb-2 text-lg">
                  {t(`stats.metrics.${metric.key}.label`)}
                </div>
                <p className="text-gray-600">
                  {t(`stats.metrics.${metric.key}.description`)}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
