
import { Button } from "@/components/ui/button";
import { LoginForm } from "./LoginForm";
import { ContactFormFields } from "./ContactFormFields";
import { TermsDialog } from "./TermsDialog";
import { OtpVerification } from "./OtpVerification";
import { useContactForm } from "@/hooks/useContactForm";
import { useTranslation } from "react-i18next";

export const ContactForm = () => {
  const {
    formState,
    isLoading,
    showLogin,
    showTerms,
    showOtpVerification,
    handleChange,
    handleSubmit,
    handleLoginSuccess,
    handleOtpVerificationSuccess,
    setShowLogin,
    setShowTerms,
  } = useContactForm();

  const { t } = useTranslation();

  if (showOtpVerification) {
    return <OtpVerification onSuccess={handleOtpVerificationSuccess} />;
  }

  if (showLogin) {
    return (
      <LoginForm 
        email={formState.email} 
        onCancel={() => setShowLogin(false)}
        onLoginSuccess={handleLoginSuccess}
      />
    );
  }

  return (
    <>
      <form onSubmit={handleSubmit} className="space-y-6" noValidate>
        <h2 className="text-2xl font-bold text-center text-[#1B365D]">
          {t('contactForm.title')}
        </h2>

        <ContactFormFields 
          formState={formState}
          onChange={handleChange}
        />

        <div className="space-y-4">
          <p className="text-sm text-gray-600">
            {t('contactForm.terms.text')}{" "}
            <button
              type="button"
              onClick={() => setShowTerms(true)}
              className="text-[#1B365D] hover:underline font-medium"
            >
              {t('contactForm.terms.link')}
            </button>
          </p>

          <Button 
            type="submit" 
            className="w-full bg-[#1B365D] hover:bg-[#152C4A]"
            disabled={isLoading}
          >
            {isLoading ? t('contactForm.submitting') : t('contactForm.submit')}
          </Button>
          
          <p className="text-sm text-gray-600 text-center">
            {t('contactForm.freeService')}
          </p>
        </div>
      </form>

      <TermsDialog
        open={showTerms}
        onOpenChange={setShowTerms}
        onAccept={() => {
          setShowTerms(false);
        }}
      />
    </>
  );
};
