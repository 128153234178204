const API_BASE_URL = "https://api.salaovi.fi";
const SUPABASE_FUNCTION_URL = "https://yfhzflexahlxpaogypbx.supabase.co/functions/v1";
const SUPABASE_ANON_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InlmaHpmbGV4YWhseHBhb2d5cGJ4Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3Mzk4MDc4NjMsImV4cCI6MjA1NTM4Mzg2M30.L06tM0eG0IvBSGuzCmgwE3w8LfbfjcFZ3P3zji9CXk0";

interface EnquiryEmailDetails {
  name: string;
  address: string;
  size: string;
  phone: string;
}

interface EnquiryEmailData {
  customerEmail: string;
  adminEmail: string;
  enquiryDetails: EnquiryEmailDetails;
}

interface ApiResponse<T = any> {
  ok: boolean;
  data?: T;
  error?: {
    message: string;
    type?: string;
  };
}

function generateRandomString(length: number = 8): string {
  const characters = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

export const apiService = {
  async login(data: { email: string; password: string }) {
    try {
      const response = await fetch(`${API_BASE_URL}/User/Login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Origin": window.location.origin,
        },
        body: JSON.stringify(data),
        credentials: 'include',
      });
      
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Server error response:', errorText);
        throw new Error('Login failed');
      }
      
      return response;
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  },

  async register(data: any) {
    try {
      console.log('Registration data being sent:', data);

      const response = await fetch(`${API_BASE_URL}/User/Register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Origin": window.location.origin,
        },
        body: JSON.stringify(data),
        credentials: 'include',
      });
      
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Server error response:', errorText);
        const errorData = JSON.parse(errorText);
        
        if (errorData.type === "EMAIL_OR_PASSWORD_ALREADY_EXIST") {
          throw new Error("EMAIL_ALREADY_EXISTS");
        }
        
        throw new Error(errorData.message || 'Registration failed');
      }
      
      return response;
    } catch (error) {
      console.error('Registration error:', error);
      throw error;
    }
  },

  async createEnquiry(accessToken: string, data: any): Promise<ApiResponse> {
    try {
      const response = await fetch(`${API_BASE_URL}/User/createEnquiry`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": `Bearer ${accessToken}`,
          "Origin": window.location.origin,
        },
        body: JSON.stringify(data),
        credentials: 'include',
      });

      const responseText = await response.text();
      console.log('Server response:', responseText);

      let parsedData;
      try {
        parsedData = JSON.parse(responseText);
      } catch (e) {
        console.error('Failed to parse response as JSON:', e);
        return {
          ok: false,
          error: {
            message: "Tapahtui virhe palvelimella",
          }
        };
      }

      if (!response.ok) {
        return {
          ok: false,
          error: {
            message: parsedData.message || "Tapahtui virhe palvelimella",
            type: parsedData.type
          }
        };
      }

      return {
        ok: true,
        data: parsedData
      };
    } catch (error) {
      console.error('Create enquiry error:', error);
      return {
        ok: false,
        error: {
          message: error instanceof Error ? error.message : "Tapahtui virhe palvelimella"
        }
      };
    }
  },

  async verifyOtp(data: { countryCode: string; contactNumber: string; code: string }, authorization?: string) {
    try {
      console.log('Verifying OTP with data:', data);
      const headers: Record<string, string> = {
        "Content-Type": "application/json",
        "Accept": "application/json",
      };

      if (authorization) {
        headers["Authorization"] = `Bearer ${authorization}`;
      }

      const response = await fetch(`${API_BASE_URL}/User/verifyOtp`, {
        method: "POST",
        headers,
        body: JSON.stringify({
          countryCode: data.countryCode,
          contactNumber: data.contactNumber,
          code: data.code
        }),
      });
      
      if (!response.ok) {
        const errorText = await response.text();
        console.error('OTP verification error response:', errorText);
        throw new Error('OTP verification failed');
      }
      
      const result = await response.json();
      console.log('OTP verification response:', result);
      return result;
    } catch (error) {
      console.error('Verify OTP error:', error);
      throw error;
    }
  },

  async resendOtp(data: { countryCode: string; contactNumber: string }) {
    try {
      console.log('Resending OTP with data:', data);
      const response = await fetch(`${API_BASE_URL}/User/ResendOTP`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
        body: JSON.stringify({
          countryCode: data.countryCode,
          contactNumber: data.contactNumber
        }),
      });
      
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Resend OTP error response:', errorText);
        throw new Error('Failed to resend OTP');
      }
      
      const result = await response.json();
      console.log('Resend OTP response:', result);
      return result;
    } catch (error) {
      console.error('Resend OTP error:', error);
      throw error;
    }
  },

  async forgotPassword(emailId: string) {
    try {
      const response = await fetch(`${API_BASE_URL}/User/forgotPassword`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Origin": window.location.origin,
        },
        body: JSON.stringify({ emailId }),
        credentials: 'include',
      });
      
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Server error response:', errorText);
        throw new Error('Failed to send password reset link');
      }
      
      return response;
    } catch (error) {
      console.error('Forgot password error:', error);
      throw error;
    }
  },

  async sendEnquiryEmails(data: EnquiryEmailData) {
    try {
      console.log('Sending enquiry emails with data:', data);
      const response = await fetch(`${SUPABASE_FUNCTION_URL}/send-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${SUPABASE_ANON_KEY}`,
          "apikey": SUPABASE_ANON_KEY,
          "Origin": window.location.origin,
        },
        body: JSON.stringify(data),
      });
      
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Send enquiry emails error response:', errorText);
        throw new Error('Failed to send notification emails');
      }
      
      const responseData = await response.json();
      console.log('Send enquiry emails response:', responseData);
      return responseData;
    } catch (error) {
      console.error('Send enquiry emails error:', error);
      throw error;
    }
  },
};
