
import { supabase } from "@/integrations/supabase/client";

interface ZipCodePrice {
  zip_code: string;
  price_per_sqm: number;
}

export const getZipCodePrice = async (zipCode: string): Promise<number | null> => {
  console.log("Looking for zipcode:", zipCode);
  
  // Clean the zipcode input (remove spaces, etc.)
  const cleanZipCode = zipCode.replace(/\s+/g, '').trim();
  
  // First, try to get exact match
  const { data: priceData, error } = await supabase
    .from('zip_code_prices')
    .select('price_per_sqm')
    .eq('zip_code', cleanZipCode)
    .maybeSingle();
    
  if (error) {
    console.error("Error fetching price:", error);
    return null;
  }
  
  // If exact match found, return it
  if (priceData && priceData.price_per_sqm) {
    console.log("Price data found:", priceData);
    return priceData.price_per_sqm;
  }
  
  // If no exact match, try to find nearest zip code (first 3 digits)
  if (cleanZipCode.length >= 3) {
    const zipPrefix = cleanZipCode.substring(0, 3);
    
    const { data: similarPriceData, error: similarError } = await supabase
      .from('zip_code_prices')
      .select('price_per_sqm')
      .like('zip_code', `${zipPrefix}%`)
      .order('zip_code')
      .limit(1)
      .maybeSingle();
    
    if (!similarError && similarPriceData && similarPriceData.price_per_sqm) {
      console.log("Found similar zipcode price data:", similarPriceData);
      return similarPriceData.price_per_sqm;
    }
  }
  
  // If still no match, return average price as fallback
  const { data: avgPriceData, error: avgError } = await supabase
    .from('zip_code_prices')
    .select('price_per_sqm')
    .order('price_per_sqm', { ascending: false })
    .limit(50);
    
  if (!avgError && avgPriceData && avgPriceData.length > 0) {
    // Calculate average from 50 random zip codes
    const avgPrice = avgPriceData.reduce((sum, item) => sum + parseFloat(item.price_per_sqm.toString()), 0) / avgPriceData.length;
    console.log("Using average price as fallback:", avgPrice);
    return avgPrice;
  }
  
  console.log("No price data found for zipcode:", cleanZipCode);
  return null;
};

export const calculateEstimate = async (zipCode: string, size: number): Promise<number | null> => {
  try {
    if (!zipCode || !size || size <= 0) {
      console.error("Invalid input for estimation:", { zipCode, size });
      return null;
    }
    
    const pricePerSqm = await getZipCodePrice(zipCode);
    if (!pricePerSqm) {
      console.warn(`Could not find price for zip code: ${zipCode}`);
      return null;
    }
    
    return pricePerSqm * size;
  } catch (error) {
    console.error("Error calculating estimate:", error);
    return null;
  }
};
