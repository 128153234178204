
import { Button } from "@/components/ui/button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Menu, LogIn, UserPlus, Globe } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

export const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const getLanguageLabel = (lang: string) => {
    switch (lang) {
      case 'fi': return 'FI';
      case 'sv': return 'SV';
      case 'en': return 'EN';
      case 'et': return 'ET';
      case 'de': return 'DE';
      default: return lang.toUpperCase();
    }
  };

  const changeLanguage = (lng: string) => {
    const currentPath = location.pathname;
    const pathWithoutLang = currentPath.replace(/^\/[^\/]+\/[^\/]+/, '');
    const newPath = `/${lng}/${lng === 'fi' ? 'fin' : lng === 'sv' ? 'swe' : lng === 'en' ? 'eng' : lng === 'et' ? 'est' : 'ger'}${pathWithoutLang}`;
    i18n.changeLanguage(lng);
    navigate(newPath);
  };

  // Function to get current language path prefix
  const getCurrentLangPath = () => {
    const lang = i18n.language;
    return `/${lang}/${lang === 'fi' ? 'fin' : lang === 'sv' ? 'swe' : lang === 'en' ? 'eng' : lang === 'et' ? 'est' : 'ger'}`;
  };

  return (
    <nav className="bg-white shadow-sm fixed w-full top-0 z-50">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between items-center h-16">
          <Link to={getCurrentLangPath()} className="flex items-center">
            <img 
              src="/lovable-uploads/aee5e23a-d394-494d-91f1-bd4e45b5d1bc.png" 
              alt="SALAOVI" 
              className="h-8 w-auto"
            />
          </Link>

          <div className="hidden md:flex items-center gap-4">
            <Link to={`${getCurrentLangPath()}`} className="text-gray-600 hover:text-[#1B365D]">
              {t('nav.compare')}
            </Link>
            <Link to={`${getCurrentLangPath()}/hinta-arvio`} className="text-gray-600 hover:text-[#1B365D]">
              {t('nav.estimate')}
            </Link>
            <Link to={`${getCurrentLangPath()}/blogi`} className="text-gray-600 hover:text-[#1B365D]">
              {t('nav.blog')}
            </Link>
            <Link to={`${getCurrentLangPath()}/valittajille`} className="text-gray-600 hover:text-[#1B365D]">
              {t('nav.forAgents')}
            </Link>

            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" size="sm" className="flex items-center gap-2">
                  <Globe className="h-4 w-4" />
                  <span>{getLanguageLabel(i18n.language)}</span>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="w-24 bg-white">
                <DropdownMenuItem 
                  onClick={() => changeLanguage("fi")}
                  className={i18n.language === "fi" ? "bg-gray-100" : ""}
                >
                  Suomi
                </DropdownMenuItem>
                <DropdownMenuItem 
                  onClick={() => changeLanguage("sv")}
                  className={i18n.language === "sv" ? "bg-gray-100" : ""}
                >
                  Svenska
                </DropdownMenuItem>
                <DropdownMenuItem 
                  onClick={() => changeLanguage("en")}
                  className={i18n.language === "en" ? "bg-gray-100" : ""}
                >
                  English
                </DropdownMenuItem>
                <DropdownMenuItem 
                  onClick={() => changeLanguage("et")}
                  className={i18n.language === "et" ? "bg-gray-100" : ""}
                >
                  Eesti
                </DropdownMenuItem>
                <DropdownMenuItem 
                  onClick={() => changeLanguage("de")}
                  className={i18n.language === "de" ? "bg-gray-100" : ""}
                >
                  Deutsch
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>

            <div className="flex items-center gap-3">
              <a 
                href="https://partners.salaovi.fi/register"
                className="inline-flex items-center gap-2 px-4 py-2 text-[#1B365D] hover:bg-gray-50 rounded-md transition-colors"
                target="_blank"
                rel="noopener noreferrer"
              >
                <UserPlus className="h-4 w-4" />
                {t('nav.register')}
              </a>
              <a 
                href="https://partners.salaovi.fi/login"
                className="inline-flex items-center gap-2 bg-[#1B365D] hover:bg-[#152C4A] text-white px-4 py-2 rounded-md shadow-sm transition-colors"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LogIn className="h-4 w-4" />
                {t('nav.login')}
              </a>
            </div>
          </div>

          <button
            className="md:hidden p-2"
            onClick={() => setIsOpen(!isOpen)}
          >
            <Menu className="h-6 w-6 text-[#1B365D]" />
          </button>
        </div>

        {isOpen && (
          <div className="md:hidden py-4 space-y-4">
            <Link
              to={getCurrentLangPath()}
              className="block text-gray-600 hover:text-[#1B365D] py-2"
              onClick={() => setIsOpen(false)}
            >
              {t('nav.compare')}
            </Link>
            <Link
              to={`${getCurrentLangPath()}/hinta-arvio`}
              className="block text-gray-600 hover:text-[#1B365D] py-2"
              onClick={() => setIsOpen(false)}
            >
              {t('nav.estimate')}
            </Link>
            <Link
              to={`${getCurrentLangPath()}/blogi`}
              className="block text-gray-600 hover:text-[#1B365D] py-2"
              onClick={() => setIsOpen(false)}
            >
              {t('nav.blog')}
            </Link>
            <Link
              to={`${getCurrentLangPath()}/valittajille`}
              className="block text-gray-600 hover:text-[#1B365D] py-2"
              onClick={() => setIsOpen(false)}
            >
              {t('nav.forAgents')}
            </Link>

            <div className="flex items-center gap-2 py-2">
              <Globe className="h-4 w-4 text-gray-600" />
              <button
                onClick={() => changeLanguage("fi")}
                className={`px-3 py-1 rounded-md ${
                  i18n.language === "fi" ? "bg-gray-100" : ""
                }`}
              >
                Suomi
              </button>
              <button
                onClick={() => changeLanguage("sv")}
                className={`px-3 py-1 rounded-md ${
                  i18n.language === "sv" ? "bg-gray-100" : ""
                }`}
              >
                Svenska
              </button>
              <button
                onClick={() => changeLanguage("en")}
                className={`px-3 py-1 rounded-md ${
                  i18n.language === "en" ? "bg-gray-100" : ""
                }`}
              >
                English
              </button>
              <button
                onClick={() => changeLanguage("et")}
                className={`px-3 py-1 rounded-md ${
                  i18n.language === "et" ? "bg-gray-100" : ""
                }`}
              >
                Eesti
              </button>
              <button
                onClick={() => changeLanguage("de")}
                className={`px-3 py-1 rounded-md ${
                  i18n.language === "de" ? "bg-gray-100" : ""
                }`}
              >
                Deutsch
              </button>
            </div>

            <div className="space-y-3">
              <a 
                href="https://partners.salaovi.fi/register"
                className="block w-full text-[#1B365D] hover:bg-gray-50 px-4 py-2 rounded-md transition-colors inline-flex items-center gap-2"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => setIsOpen(false)}
              >
                <UserPlus className="h-4 w-4" />
                {t('nav.register')}
              </a>
              <a 
                href="https://partners.salaovi.fi/login"
                className="block w-full bg-[#1B365D] hover:bg-[#152C4A] text-white px-4 py-2 rounded-md shadow-sm transition-colors inline-flex items-center gap-2"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => setIsOpen(false)}
              >
                <LogIn className="h-4 w-4" />
                {t('nav.login')}
              </a>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};
