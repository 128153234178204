
interface ErrorMessage {
  en: string;
  fi: string;
}

interface ErrorType {
  statusCode: number;
  customMessage: ErrorMessage;
  type: string;
}

export const handleApiError = (error: any): { title: string; description: string; variant: "default" | "destructive" } => {
  // If the error is a JSON string, parse it
  let errorData;
  if (typeof error?.body === 'string') {
    try {
      errorData = JSON.parse(error.body);
    } catch (e) {
      errorData = error;
    }
  } else {
    errorData = error;
  }

  const errorType = errorData?.type;
  const customMessage = errorData?.message;
  const language = 'fi'; // Default to Finnish

  // Default error message
  let errorMessage = {
    title: "Virhe",
    description: "Hups, jotain meni pieleen",
    variant: "destructive" as const
  };

  switch (errorType) {
    case "EXISTING_ENQUIRY":
      errorMessage = {
        title: "Vertailu jo käynnissä",
        description: "Edellinen tilauksesi on vielä kesken",
        variant: "destructive"
      };
      break;
    case "INVALID_CREDENTIALS":
      errorMessage = {
        title: "Kirjautuminen epäonnistui",
        description: "Sähköposti tai salasana väärin",
        variant: "destructive"
      };
      break;
    case "EMAIL_ALREADY_EXIST":
      errorMessage = {
        title: "Sähköposti käytössä",
        description: "Sähköposti on jo käytössä. Kirjaudu tilillesi tai käytä toista salasanaa",
        variant: "destructive"
      };
      break;
    case "SORRY_NO_AGENT_FIND":
      errorMessage = {
        title: "Ei välittäjiä alueella",
        description: "Hups! Emme ole ihan vielä täällä. Yritä muutaman päivän päästä uudelleen!",
        variant: "destructive"
      };
      break;
    case "WRONG_OTP":
      errorMessage = {
        title: "Virheellinen koodi",
        description: "Tarkista koodi ja yritä uudelleen",
        variant: "destructive"
      };
      break;
    case "OTP_NOT_VERIFIED":
      errorMessage = {
        title: "Vahvistamaton numero",
        description: "Puhelinnumeroa ei ole vahvistettu",
        variant: "destructive"
      };
      break;
    case "USER_NOT_FOUND":
      errorMessage = {
        title: "Käyttäjää ei löydy",
        description: "Asiakasta ei löydy järjestelmästä",
        variant: "destructive"
      };
      break;
    case "UNAUTHORIZED":
      errorMessage = {
        title: "Ei oikeuksia",
        description: "Sinulla ei ole oikeutta suorittaa toimintoa",
        variant: "destructive"
      };
      break;
    case "USER_BLOCKED":
      errorMessage = {
        title: "Tili estetty",
        description: "Tili poistettu käytöstä. Ota yhteyttä ylläpitoon",
        variant: "destructive"
      };
      break;
    default:
      if (customMessage) {
        errorMessage.description = customMessage;
      }
  }

  return errorMessage;
};
